import React, {useEffect, useState} from "react";
import {
  TextField,
  Typography,
  Stack, Card, CardContent, CardHeader, Link, Button, Container, IconButton
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import useProjects from "../api/useProjects";
import DocumentList from "../components/DocumentList";
import LabeledSwitch from "../components/LabeledSwitch";
import ProjectPermissions from "../components/projectSettings/ProjectPermissions";
import {OnboardingTooltip} from "../components/StyledTooltip";
import {LoadingButton} from "@mui/lab";
import DeleteProjectDialog from "../components/DeleteProjectDialog";
import LLMSelect from "../components/projectSettings/LLMSelect";
import {ArrowBackRounded} from "@mui/icons-material";


const ProjectSettingsView = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { projectId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const { getProject, createProject, updateProject, generateProjectDescription } = useProjects()

  const isOnboarding = searchParams.get("onboarding") === "true"
  const isNewProject = projectId === "new" || projectId === undefined

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false)

  const [llm, setLLM] = useState("gpt-4")
  const [agentInstructions, setAgentInstructions] = useState("")
  const [agentAccess, setAgentAccess] = useState({
    documentSearch: true,
    webSearch: false,
    youtubeSearch: false,
    calculator: false
  })

  const [errorFieldIds, setErrorFieldIds] = useState<string[]>([])
  const [isUpserting, setIsUpserting] = useState(false)

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [onboardingStep, setOnboardingStep] = useState("project-name")
  const onboardingSteps: { [key: string]: { title: string, body?: string } } = {
    "project-name": {
      title: "Set the project name",
      body: "This could be something like \"Human Resources\" or \"Customer Support\"."
    },
    "project-description": {
      title: "Set the project description",
      body: "Optionally give the project a description or generate one!"
    },
    "llm-select": {
      title: "Select the large language model",
      body: "This is the AI that will be used to generate responses."
    },
    "agent-instructions": {
      title: "Set the AI instructions",
      body: "This is the prompt that will be used to generate responses."
    },
    "agent-access": {
      title: "Select the AI access",
      body: "Select the tools your AI should have access to."
    },
    "documents": {
      title: "Add documents",
      body: "Add documents to your project to help staff find information. Documents can be uploaded or linked from Google Drive."
    },
    "permissions": {
      title: "Set the project permissions",
      body: "Limit to just yourself? To your domain? To specific users? Or make it public!"
    }
  }

  const handleCreateProject = () => {
    if (!isValid()) return
    setIsUpserting(true)
    createProject({ name, description, agent_instructions: agentInstructions })
      .then((response) => {
        navigate(`/projects/${response.data._id}/edit`)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsUpserting(false))
  }

  const handleUpdateProject = () => {
    if (!projectId) return
    if (!isValid()) return
    setIsUpserting(true)
    updateProject({ _id: projectId, name, description, agent_instructions: agentInstructions })
      .then((response) => console.log("saved", response.data))
      .catch((err) => console.log(err))
      .finally(() => setIsUpserting(false))
  }

  const isValid = (): boolean => {
    setErrorFieldIds([])
    if (name.trim() === "") {
      setErrorFieldIds(["project-name-textfield"])
      scrollToElement("project-name-textfield")
      return false
    }
    return true
  }

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      let offset = element.offsetTop
      window.scroll({ top: offset, behavior: "smooth"})
    }
  }

  const generateDescription = () => {
    setIsGeneratingDescription(true)
    setDescription("Generating...")
    generateProjectDescription(name)
      .then((response) => setDescription(response.data.output))
      .catch((err) => {
        console.log(err)
        setDescription("")
      })
      .finally(() => setIsGeneratingDescription(false))
  }

  const toggleAgentAccess = (key: string, value: boolean) => {
    setAgentAccess({ ...agentAccess, [key]: value })
  }

  const goBack = () => {
    if (location.pathname.includes("edit")) {
      navigate(`/projects/${projectId}`)
    } else {
      navigate(`/projects`)
    }
  }

  // Get project metadata
  useEffect(() => {
    if (!isNewProject && projectId) {
      getProject(projectId)
        .then((response) => {
          setName(response.data.name)
          setDescription(response.data.description || "")
          setAgentInstructions(response.data.agent_instructions || "")
        })
        .catch((err) => console.log(err))
    }
  }, []);


  const header = () => (
    <Grid container spacing={2}>
      <Grid xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
        <IconButton
          onClick={goBack}
          size={"large"}
          color={"inherit"}
          edge={"start"}
          sx={{ mr: 1 }}
        >
          <ArrowBackRounded />
        </IconButton>
        <Typography variant={"h6"}>{isNewProject ? "New Project" : "Project Settings"}</Typography>
      </Grid>
    </Grid>
  )

  const projectMetadataForm = () => (
    <Grid container spacing={2} sx={{ m: -1}}>
      <Grid xs={12}>
        <OnboardingTooltip
          title={onboardingSteps["project-name"].title}
          body={onboardingSteps["project-name"].body}
          onAction={() => setOnboardingStep("project-description")}
          open={isOnboarding && onboardingStep === "project-name"}
          placement={"right"}
        >
          <TextField
            id={"project-name-textfield"}
            label={"Project Name"}
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={errorFieldIds.includes("project-name-textfield")}
            helperText={errorFieldIds.includes("project-name-textfield") ? "Project name is required" : undefined}
          />
        </OnboardingTooltip>
      </Grid>
      <Grid xs={12}>
        <OnboardingTooltip
          title={onboardingSteps["project-description"].title}
          body={onboardingSteps["project-description"].body}
          onAction={() => setOnboardingStep("llm-select")}
          open={isOnboarding && onboardingStep === "project-description"}
          placement={"right"}
        >
          <TextField
            label={"Project Description"}
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={isGeneratingDescription}
            helperText={
              <Link onClick={generateDescription} sx={{ cursor: "pointer" }}>
                ✨ Generate a description based on the title. ✨
              </Link>
            }
          />
        </OnboardingTooltip>
      </Grid>
    </Grid>
  )

  const aiSetupForm = () => (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant={"h6"}>AI Settings</Typography>
      </Grid>
      <Grid xs={12}>
        <OnboardingTooltip
          title={onboardingSteps["llm-select"].title}
          body={onboardingSteps["llm-select"].body}
          onAction={() => setOnboardingStep("agent-instructions")}
          open={isOnboarding && onboardingStep === "llm-select"}
          placement={"right"}
        >
          <div>
            <LLMSelect
              value={llm}
              onChange={(value) => setLLM(value)}
            />
          </div>
        </OnboardingTooltip>
      </Grid>
      <Grid xs={12}>
        <OnboardingTooltip
          title={onboardingSteps["agent-instructions"].title}
          body={onboardingSteps["agent-instructions"].body}
          onAction={() => setOnboardingStep("agent-access")}
          open={isOnboarding && onboardingStep === "agent-instructions"}
          placement={"right"}
        >
          <TextField
            label={"AI Agent Instructions"}
            InputLabelProps={{ shrink: true }}
            placeholder={
              "You are an AI assistant whose goal is to help answer questions and complete tasks."
            }
            helperText={
              "Skip this step and use the default instructions if you're not sure what to write."
            }
            fullWidth
            multiline
            rows={4}
            value={agentInstructions}
            onChange={(e) => setAgentInstructions(e.target.value)}
          />
        </OnboardingTooltip>
      </Grid>
      <Grid xs={12}>
        <OnboardingTooltip
          title={onboardingSteps["agent-access"].title}
          body={onboardingSteps["agent-access"].body}
          onAction={() => setOnboardingStep("documents")}
          open={isOnboarding && onboardingStep === "agent-access"}
          placement={"right"}
        >
          <Card variant={"outlined"}>
            <CardHeader title={"AI Access"} subheader={"Select the tools your AI should have access to."} />
            <CardContent>
              <Stack spacing={2}>
                <LabeledSwitch
                  primary={"Document Search"}
                  disabled
                  checked={true} //agentAccess.documentSearch}
                  // onChange={(e, checked) => toggleAgentAccess("documentSearch", checked)}
                />
                <LabeledSwitch
                  primary={"Web Search"}
                  disabled
                  checked={agentAccess.webSearch}
                  onChange={(e, checked) => toggleAgentAccess("webSearch", checked)}
                />
                <Typography variant="body2">
                  ... Contact our team to request more tools! (e.g. Canvas integration)
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </OnboardingTooltip>
      </Grid>
    </Grid>
  )

  const documentForm = () => (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Stack>
        <Typography variant={"h6"}>Documents</Typography>
        <Typography>Add documents to your project to help staff find information.
          Documents can be uploaded or linked from Google Drive.</Typography>
        </Stack>
      </Grid>
      <Grid xs={12}>
        <OnboardingTooltip
          title={onboardingSteps["documents"].title}
          body={onboardingSteps["documents"].body}
          onAction={() => setOnboardingStep("permissions")}
          open={isOnboarding && onboardingStep === "documents"}
          placement={"right"}
        >
          <div>
            <DocumentList projectId={isNewProject ? undefined : projectId} disableUpload={isNewProject} disableDelete={isNewProject} />
          </div>
        </OnboardingTooltip>
      </Grid>
    </Grid>
  )

  const permissionsForm = () => (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Stack>
          <Typography variant={"h6"}>Permissions</Typography>
          <Typography>Specify who can access and manage this project</Typography>
        </Stack>
      </Grid>
      <Grid xs={12}>
        <OnboardingTooltip
          title={onboardingSteps["permissions"].title}
          body={onboardingSteps["permissions"].body}
          actionLabel={"Done"}
          onAction={() => setOnboardingStep("done")}
          open={isOnboarding && onboardingStep === "permissions"}
          placement={"right-start"}
        >
          <>
            {projectId && <ProjectPermissions projectId={projectId}/>}
          </>
        </OnboardingTooltip>
      </Grid>
    </Grid>
  )

  const manageProjectForm = () => (
    <Grid container spacing={2}>
      <Grid xs={12} sm={6}>
        <LoadingButton
          loading={isUpserting}
          onClick={isNewProject ? handleCreateProject : handleUpdateProject}
        >
          {isNewProject ? "Create project" : "Update project"}
        </LoadingButton>
      </Grid>
      {!isNewProject && (
        <Grid xs={12} sm={6}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant={"text"} color={"error"} onClick={() => setShowDeleteDialog(true)}>Delete project</Button>
          </div>
        </Grid>
      )}
    </Grid>
  )

  return (<>
      <Container maxWidth={"md"}>
        <Stack spacing={3} sx={{ pb: 5 }}>
          {header()}
          {projectMetadataForm()}
          {documentForm()}
          {/* reduce size of settings page.. keep this in the main layout.. */}
          {/* {permissionsForm()} */}
          {aiSetupForm()}
          {manageProjectForm()}
        </Stack>
      </Container>
    {projectId && <DeleteProjectDialog projectId={projectId} open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} />}
  </>)
}

export default ProjectSettingsView
