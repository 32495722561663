import {
  Avatar,
  Box,
  Button, Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Toolbar, useMediaQuery, useTheme
} from "@mui/material"
import {AddRounded, ArrowBack, CloseRounded} from "@mui/icons-material";
import ConversationThreadList from "../../components/reusable/ConversationThreadList";
import React, {ReactNode} from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import useProjectContainerContext from "../../contexts/useProjectContainerContext";
import useUserContext from "../../contexts/useUserContext";
import useProjects from "../../api/useProjects";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

const ProjectContainer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const shouldUseDialog = useMediaQuery(theme.breakpoints.down("lg"))

  const { projectPermissions } = useProjects()
  const { open, toggleOpen } = useProjectContainerContext()
  const { user } = useUserContext()
  const { projectId, threadId } = useParams()

  const canEdit = projectId && projectPermissions(projectId)?.some(p => p.permission.user_id === user?._id && ["owner", "editor"].includes(p.permission.role))

  const handleNewThread = () => {
    navigate(`/projects/${projectId}`)
    shouldUseDialog && toggleOpen(false)
  }
  const handleSelectThread = (threadId: string) => {
    navigate(`/projects/${projectId}/t/${threadId}`)
    shouldUseDialog && toggleOpen(false)
  }
  const handleEdit = () => {
    navigate(`/projects/${projectId}/edit`)
    shouldUseDialog && toggleOpen(false)
  }

  const header = (
    <Box
      sx={{
        // flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}>
      <ListItemButton onClick={() => navigate("/projects")} sx={{alignItems: "center"}}>
        <ArrowBack/>
        <ListItemText
          secondary={"Back to projects"}
          sx={{ml: 2}}
        />
      </ListItemButton>
      {/* <Divider variant="middle"/> */}
      <ListItemButton onClick={() => handleNewThread()}>
        <ListItemAvatar><Avatar sx={{ bgcolor: theme.palette.primary.main }}><AddRounded /></Avatar></ListItemAvatar>
        <ListItemText
          primary={"New chat"} secondary={"Start a new conversation"}
          primaryTypographyProps={{
            color: "primary",
            fontWeight: "bold"
          }}
        />
      </ListItemButton>
    </Box>
  )

  const threads = (
    <Box sx={{flexGrow: 100, overflow: "scroll"}}>
      <ConversationThreadList
        projectId={projectId}
        handleClickThread={(t) => handleSelectThread(t._id)}
        activeThreadId={threadId}
      />
    </Box>
  )

  const settingsButton = (
    <ListItemButton sx={{ my: 1, flexShrink: 0 }} onClick={handleEdit} selected={location.pathname.includes("edit")}>
      <ListItemText primary={"Settings"} />
    </ListItemButton>
  )

  const menu = (
    shouldUseDialog ? (
      <Dialog open={open} onClose={() => toggleOpen(false)} fullScreen keepMounted>
        <DialogTitle sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <span>Threads</span>
          <div style={{ flexGrow: 1 }}/>
          <IconButton
            size={"large"}
            edge={"end"}
            color={"inherit"}
            onClick={() => toggleOpen(false)}
          >
            <CloseRounded />
          </IconButton>
        </DialogTitle>
        {header}
        <Divider variant={"middle"} />
        {threads}
        {canEdit && (
          <>
            <Divider variant={"middle"} />
            {settingsButton}
          </>
        )}
      </Dialog>
    ) : (
      <Drawer
        variant={"persistent"}
        open={open}
        anchor={"left"}
        sx={{
          width: 300,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {width: 300, boxSizing: "border-box", borderRight: "none", zIndex: (theme) => theme.zIndex.appBar - 1},
          display: "flex",
          flexDirection: "column",
          zIndex: (theme) => theme.zIndex.appBar - 1,
        }}
      >
        <Toolbar />
        {header}
        <Divider variant={"middle"} />
        {threads}
        {canEdit && (
          <>
            <Divider variant={"middle"} />
            {settingsButton}
          </>
        )}
      </Drawer>
    )
  )

  return (<>
    {menu}
    <Outlet />
  </>)
}

export default ProjectContainer
