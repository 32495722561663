export default interface ConversationThread {
  _id: string
  thread_id: string
  project_id?: string
  user_id: string
  name?: string
  initial_query: string
  latest_response?: string
  status?: ConversationThreadStatus

  updated_at: string
  user: {
    user_id: string
    first_name: string
    last_name: string
    email: string
    picture: string
  }
}

export enum ConversationThreadStatus {
  success = "success",
  help = "help",
}