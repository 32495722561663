import {Button, Card, IconButton, ListItem, Stack, Tooltip, Typography, useTheme} from "@mui/material";
import ReactMarkdown from "react-markdown";
import {ContentPaste} from "@mui/icons-material";
import {useSnack} from "../../../contexts/useSnackContext";
import LoadingIndicator from "../../animations/LoadingIndicator";
// import {IconNumber1Small, IconNumber2Small, IconNumber3Small, IconNumber4Small} from "@tabler/icons-react";
import "./AIMessage.css"
import CodeBlock from "../../markdownComponents/CodeBlock";
import {IconUtils} from "../../../utils";
import PDFPreviewDialog, {DocumentType} from "../PDFPreviewDialog";
import {useState} from "react";
interface AIMessageProps {
  name?: string;

  message?: string;
  step?: string;
  documents?: DocumentType[];

  simulateTyping?: boolean;
  disableCopy?: boolean;
}

const AIMessage = (props: AIMessageProps) => {
  const { name, message, step, documents, simulateTyping, disableCopy } = props;

  const snackbar = useSnack()
  const theme = useTheme()

  const [selectedDocument, setSelectedDocument] = useState<DocumentType|null>(null)
  const [isPDFPreviewOpen, setIsPDFPreviewOpen] = useState(false)

  const stepLabelMap: { [step: string]: string } = {
    "analyzing": "Analyzing...",
    "load_documents": "Finding relevant documents..."
  }

  // const [typedMessage, setTypedMessage] = useState("");
  // const [isDoneTyping, setIsDoneTyping] = useState(false);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      snackbar.addSuccessMessage("Copied to clipboard!")
    } catch (err) {
      console.error("Failed to copy: ", err);
      snackbar.addErrorMessage("Failed to copy to clipboard.")
    }
  }

  const handleSourceClick = (document: DocumentType) => {
    setSelectedDocument(document)
    setIsPDFPreviewOpen(true)
  }


  // useEffect(() => {  // TODO: @Joe - Refactor to a "simulate typing" effect for things like the no projects banner.
  //   if (!message) return;
  //
  //   if (disableTyping) {
  //     setTypedMessage(message);
  //     setIsDoneTyping(true);
  //     return;
  //   }
  //
  //   setIsDoneTyping(false);
  //
  //   let res = message.trim();
  //   if (res === typedMessage) return;
  //
  //   setTypedMessage(res[0]);
  //
  //   let typing: NodeJS.Timeout;
  //
  //   const typeResponse = () => {
  //     let index = 0;
  //     const responseLength = res.length;
  //     typing = setInterval(() => {
  //       if (index === responseLength - 1) {
  //         clearInterval(typing);
  //         setIsDoneTyping(true)
  //       } else {
  //         setTypedMessage((prevTypedMessage) => {
  //           index++;
  //           return prevTypedMessage + res[index]
  //         });
  //       }
  //     }, 5);
  //   };
  //
  //   typeResponse();
  //
  //   return () => clearInterval(typing);
  // }, [message]);

  const stepIndicator = () => (
    <Stack direction={"row"} alignItems={"center"} spacing={1}>
      <LoadingIndicator size={theme.typography.body1.fontSize} />
      <Typography variant={"body2"}>
        {step ? stepLabelMap[step] : "Analyzing..."}
      </Typography>
    </Stack>
  )

  const messageBody = () => (
    <>
      <ReactMarkdown
        className={"ai-response"}
        components={{
          code: CodeBlock,
        }}
      >
        {message || ""}
      </ReactMarkdown>
      {documents && documents.length !== 0 && documents.map((document, idx) => (
        <Button
          key={idx}
          variant={"pureText"}
          sx={{ color: "text.secondary", minWidth: 0}}
          onClick={() => handleSourceClick(document)}
        >
          {IconUtils.getTablarIcon(`IconNumber${idx + 1}Small`) || `${idx + 1}`}
        </Button>
      ))}
    </>
  )


  return (<>
    <Card sx={{ overflow: "unset" }}>
      <ListItem
        alignItems={"flex-start"}
      >
        <Stack alignItems={"start"}>
          <Typography variant={"body1"}>{name || "Assistant"}</Typography>
          {message?.trim() ? messageBody() : stepIndicator()}
        </Stack>
        <div style={{ flexGrow: 1 }} />
        <Tooltip title={"Copy to clipboard"} placement={"right"} arrow>
          <IconButton sx={{ display: disableCopy ? "none" : "inherit" }} onClick={() => copyToClipboard((message || "").replaceAll("\n\n", "\n"))} aria-label={"copy to clipboard"}>
            <ContentPaste fontSize={"small"} />
          </IconButton>
        </Tooltip>
      </ListItem>
    </Card>
    {selectedDocument && <PDFPreviewDialog open={isPDFPreviewOpen} onClose={() => setIsPDFPreviewOpen(false)} {...selectedDocument} />}
  </>)
}

export default AIMessage
