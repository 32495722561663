import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeBlock = (props) => {
  const {children, className, node, ...rest} = props
  const match = /language-(\w+)/.exec(className || '')
  return match ? (
    <SyntaxHighlighter
      {...rest}
      PreTag="div"
      children={String(children).replace(/\n$/, '')}
      language={match[1]}
      style={materialDark}
      customStyle={{
        borderRadius: 12,
      }}
      showLineNumbers
    />
  ) : (
    <code {...rest} className={className}>
      {children}
    </code>
  )
};

export default CodeBlock;
