import axios from "axios";
import User from "../types/User";

const useUser = () => {
  const me = (): Promise<{ data: User }> => axios.get("/api/users/me")
  const list = (q?:string): Promise<{ data: User[] }> => axios.get("/api/users", { params: { q: q || "" } })

  return {
    me,
    list,
  }
}

export default useUser
