import React from 'react';
import {Box, useTheme} from "@mui/material";
import {StringUtils} from "../../utils";

interface LoadingIndicatorProps {
  color?: string;
  size?: number | string;
}

const LoadingIndicator = ({ color, size = "40px" }: LoadingIndicatorProps) => {
  const theme = useTheme()

  const background = color ?? theme.palette.primary.main
  const bgc = StringUtils.hexToRgb(background)!;

  const pulseStyle = {
    border: `2px solid ${background}`,
    borderRadius: "50%",
    backgroundColor: `rgba(${bgc.r}, ${bgc.g}, ${bgc.b}, 0.2)`,
    height: size,
    width: size,
    position: "absolute",
    animation: "pulsate 2s infinite",
    "@keyframes pulsate": {
      "0%": {
        transform: "scale(0.5)",
        opacity: 1,
      },
      "70%": {
        transform: "scale(1.2)",
        opacity: 0,
      },
      "100%": {
        transform: "scale(0.5)",
        opacity: 1,
      },
    }
  }

  return (
    <Box sx={{ height: size, width: size}}>
      <Box sx={{ position: "relative" }}>
        <Box sx={pulseStyle} />
        <Box sx={{...pulseStyle, animationDelay: "0.4s" }} />
        <Box sx={{...pulseStyle, animationDelay: "0.8s" }} />
      </Box>
    </Box>
  )
}

export default LoadingIndicator;
