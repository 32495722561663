import React, { useEffect, useState } from "react";

import {
  Box,
  Button, Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select, Stack,
} from "@mui/material";
import {Close as CloseIcon, ArrowDropDown} from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2"
import UserSearchAutocomplete from "../UserSearchAutocomplete";
import { FreeSoloUser } from "../../types/User";
import { ProjectPermission, UserProjectPermission, ProjectRole } from "../../types/Project";
import useProjects from "../../api/useProjects";
import UserInfoButton from "../UserInfoButton";
import useUserContext from "../../contexts/useUserContext";


const ProjectPermissions = ({projectId,hideCurrentUser=false}:{projectId:string,hideCurrentUser?:boolean}) => {
  const { user } = useUserContext()

  const { projectPermissions, addProjectPermissions } = useProjects()
  const [targetUsers, setTargetUsers] = useState<FreeSoloUser[]>([])
  const [targetRole, setTargetRole] = useState<ProjectRole>(ProjectRole.viewer)
  const handleUserChange = (users:FreeSoloUser[]) => setTargetUsers(users)

  const inviteNewPermissions = () => {
    addProjectPermissions(projectId, targetUsers.map((newUser) => {
      return {email: newUser.email, user_id: newUser._id, role: targetRole} as ProjectPermission
    }))
      .then((response) => {
        setTargetUsers([])
      })
      .catch((error) => console.log(error))
  }

  return (
    <Grid container spacing={2}>
      {/* Disabled until this is actually functional */}
      {/* <Grid xs={12}>
        <FormControl fullWidth>
          <InputLabel id="sharing-label">Sharing</InputLabel>
          <Select
            labelId="sharing-label"
            id="sharing"
            value={"private"}
            label="Sharing"
          >
            <MenuItem value={"private"}>Private</MenuItem>
            <MenuItem value={"public"} disabled>Public (anyone with this link)</MenuItem>
            <MenuItem value={"domain"} disabled>Anyone in this domain</MenuItem>
            <MenuItem value={"restricted"} disabled>Restricted to select users</MenuItem>
          </Select>
        </FormControl>
      </Grid> */}
      <Grid xs={12}>
        <Box display="flex" alignItems="center">
          <Box mr={2} sx={{ flexGrow: 1 }}>
            <UserSearchAutocomplete handleChange={handleUserChange} selectedUsers={targetUsers}/>
          </Box>
          <Box mr={2}>
            <Select
              id="select"
              variant="outlined"
              value={targetRole}
              onChange={(event) => setTargetRole(event.target.value as ProjectRole)}
              sx={{width: 100}}
            >
              {/* use the ProjectRole enum as menu options */}
              {Object.keys(ProjectRole).map((key, index) => {
                const optionLabel = ProjectRole[key as keyof typeof ProjectRole]
                return (
                  <MenuItem
                    key={index}
                    value={ProjectRole[key as keyof typeof ProjectRole]}
                  >
                    {/* @ts-ignore */}
                    {optionLabel.at(0).toUpperCase() + optionLabel.slice(1)}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>
          <Box mr={2}>
            <Button onClick={inviteNewPermissions} disabled={targetUsers.length === 0}>
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      {projectPermissions(projectId).filter(p => !hideCurrentUser || (hideCurrentUser && user?._id !== p.user?._id)).map((permission, index) => (
        <Grid key={index} xs={12} sx={{m:0}}>
          <PermissionListItem key={index} projectId={projectId} permission={permission} />
        </Grid>
      ))}
    </Grid>
  );
}

const PermissionListItem = ({ projectId, permission }: { projectId: string, permission: UserProjectPermission }) => {
  const { user } = useUserContext()
  const { updateProjectPermission, deleteProjectPermission } = useProjects()

  const [mounted, setMounted] = useState(false)
  const [role, setRole] = useState<ProjectRole>(permission.permission.role)
  const [updating, setUpdating] = useState(false)

  const handleUpdate = () => {
    setUpdating(true)
    updateProjectPermission(projectId, permission.permission._id, role)
      .then((response) => {})
      .catch((error) => console.log(error))
      .finally(() => {
        setTimeout(() => setUpdating(false), 500)
      })
  }

  const handleRemove = () => {
    deleteProjectPermission(projectId, permission.permission._id)
      .then((response) => {})
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (mounted) {
      handleUpdate()
    } else {
      setMounted(true)
    }
  }, [role]);

  const PermissionSelect = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    let label = ProjectRole[role]
    const colors: { [key in ProjectRole]: string } = {
      [ProjectRole.owner]: "error",
      [ProjectRole.editor]: "warning",
      [ProjectRole.viewer]: "info",
    }

    return (<>
      <Box sx={{ position: "relative" }}>
        <Chip
          // @ts-ignore
          label={label.at(0).toUpperCase() + label.slice(1)}
          onClick={(e: { currentTarget: React.SetStateAction<HTMLElement | null>; }) => setAnchorEl(e.currentTarget)}
          deleteIcon={<ArrowDropDown />}
          onDelete={(e) => setAnchorEl(e.currentTarget)}
          // @ts-ignore
          color={colors[role]}
          variant={"outlined"}
          disabled={user?._id === permission.user?._id || updating}
        />
        {updating && <CircularProgress
          size={25}
          // @ts-ignore
          color={colors[role]}
          sx={{position: "absolute", top: "13%", right: "4%", zIndex: 1}}
        />}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'role-select-button',
        }}
      >
        {/* use the ProjectRole enum as menu options */}
        {Object.keys(ProjectRole).map((key, index) => {
          const optionLabel = ProjectRole[key as keyof typeof ProjectRole]
          return (
            <MenuItem
              key={index}
              value={ProjectRole[key as keyof typeof ProjectRole]}
              onClick={() => setRole(ProjectRole[key as keyof typeof ProjectRole])}
            >
              {/* @ts-ignore */}
              {optionLabel.at(0).toUpperCase() + optionLabel.slice(1)}
            </MenuItem>
          )
        })}
      </Menu>
    </>)
  }

  return (
    <UserInfoButton
      user={permission.user}
      invitedEmail={permission.permission.email}
      secondaryAction={
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <PermissionSelect />
          <IconButton disabled={user?._id === permission.user?._id} onClick={handleRemove}>
            <CloseIcon />
          </IconButton>
        </Stack>
      }
    />
  )
}


export default ProjectPermissions;
