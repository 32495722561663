import React, {useEffect, useState} from "react";

import {Add as AddIcon} from "@mui/icons-material";
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import useProjects from "../../api/useProjects";
import {UserProjectPermission} from "../../types/Project";

import ProjectPermissions from "../projectSettings/ProjectPermissions";
import {useMediaQuery, useTheme} from "@mui/material";

const AvatarPermissionsGroup = ({projectId}: { projectId: string }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [open, setOpen] = useState(false)
  const { projectPermissions } = useProjects()
  const [currentPermissions, setCurrentPermissions] = useState<UserProjectPermission[]>()

  useEffect(() => {
    if (projectId) {
      const permissions = projectPermissions(projectId)
      if (permissions) {
        setCurrentPermissions(permissions)
      }
    }
  }, [projectId, projectPermissions])


  return (
    <>
      {/* avatar group with hover */}
      <Button variant={"outlined"} size={"small"} onClick={() => setOpen(true)} >
        Collaborators
        {!isMobile && <AvatarGroup max={5} sx={{cursor: "pointer", ml: 1}}>
          {currentPermissions?.filter(p => p.permission.user_id).map((p, index) => (
            <Avatar key={index} alt={p.user?.first_name?.charAt(0)} src={p.user?.picture}
                    sx={{width: 24, height: 24}}/>
          ))}
          <Avatar sx={{width: 24, height: 24}}><AddIcon fontSize={"small"}/></Avatar>
        </AvatarGroup>}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth={"sm"} fullWidth>
        <DialogTitle id={"confetti-reward"}>Invite others</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ pt: 1 }}>
            <DialogContentText>
              Add content contributors to the project or invite others to chat with the AI assistant.
            </DialogContentText>
            {projectId &&
              <ProjectPermissions projectId={projectId}/>
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Done</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AvatarPermissionsGroup
