import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  CardMedia,
  useTheme
} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import { StringUtils } from "../utils";

interface ProjectCardProps {
  src?: string
  image?: string
  imgBackgroundColor?: string
  project_id: string
  title: string
  subtitle: string
  onClick?: () => void
  compact?: boolean
}

const ProjectCard = (props: ProjectCardProps) => {
  const theme = useTheme()

  const background = props.imgBackgroundColor
    ? theme.palette.augmentColor({
      color: {
        main: props.imgBackgroundColor
      },
      name: "background",
    })
    : theme.palette.augmentColor({
      color: {
        main: StringUtils.stringToColor(props.project_id)
      },
      name: "background",
    })

  const animationProps = {
    background: `linear-gradient(90deg, ${background.light}, ${background.dark})`,
    backgroundSize: "200% 100%",
    animation: "smoothWavyGradient 5s infinite",
    "@keyframes smoothWavyGradient": {
      "0%": {
        backgroundPosition: "0 0",
      },
      "50%": {
        backgroundPosition: "100% 0",
      },
      "100%": {
        backgroundPosition: "0 0",
      },
    },
  }

  return (
    <Card>
      <CardActionArea onClick={props.onClick}>
        <CardMedia
          sx={{
            height: props.compact ? 10 : 140,
            ...((props.src || props.image) ? {} : animationProps)
          }}
          src={props.src}
          image={props.image}
          component={"div"}
        />
        <CardHeader
          title={props.title}
          titleTypographyProps={{
            sx: {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              minHeight: '1.25em'  // Height of one line of text
            }
          }}
          subheader={props.subtitle}
          subheaderTypographyProps={{
            sx: {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              minHeight: '2.85em'  // Height of two lines of text
            }
          }}
        />
      </CardActionArea>
    </Card>
  )
}


export const AddProjectCard = ({ onGuidanceClick }: { onGuidanceClick?: () => void }) => {
  const navigate = useNavigate()

  const navigateToNewProject = () => navigate("/projects/new")

  return (
    <Card variant={"outlined"}>
      <CardHeader title={"New Project"} />
      <CardActions>
        <Button onClick={() => onGuidanceClick ? onGuidanceClick() : {}}>Create new project</Button>
        {/* <Button size={"small"} onClick={navigateToNewProject}>Create new project</Button> */}
        {/* <Button size={"small"} variant={"outlined"} onClick={() => onGuidanceClick ? onGuidanceClick() : {}}>Guide me</Button> */}
      </CardActions>
    </Card>
  )
}


export default ProjectCard
