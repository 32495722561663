import {Routes as DomRoutes, Route, Outlet, Navigate, useLocation} from 'react-router-dom'
import ProjectsView from "./views/ProjectsView";
import useUserContext from "./contexts/useUserContext";
import React from "react";
import LoginPage from './views/LoginPage';
import ConversationManager from './views/ConversationManagerView';
import ProjectSettingsView from "./views/ProjectSettingsView";
import NoAccessPage from "./views/NoAccessPage";
import Playground from "./views/Playground";
import ConversationView from "./views/ConversationView/ConversationView";
import AppHeader from "./components/navigation/AppHeader";
import ProjectContainer from "./views/ConversationView/ProjectContainer";

function PrivateRoute() {
  const user = useUserContext()
  const location = useLocation()

  if (user.authenticationStatus === "authenticated") {

    if (  // Gave us a private unadulterated page to test components on.
      location.pathname === "/playground" &&
      !["aaron@schoolytics.com", "jose@schoolytics.com"].includes(user.user!.email)
    ) {
      return <Navigate to={"/projects"} />
    }
    return (<AppHeader>
      <Outlet/>
    </AppHeader>)
  } else if (user.authenticationStatus === "unauthenticated") {  // Else, redirect to log in.
    return <Navigate to={"/login"} />
  } else {  // If still fetching.
    return <></>
  }
}

export default function Routes() {
  return (
    <DomRoutes>
      <Route path={"/login"} element={<LoginPage />} />
      <Route path={"noaccess"} element={<NoAccessPage />} />
      <Route path={"*"} element={<PrivateRoute />}>
        <Route path={"playground"} element={<Playground />} />
        <Route path={"projects"} element={<ProjectsView />} />
        <Route path={"projects/new"} element={<ProjectSettingsView />} />
        <Route path={"projects"} element={<ProjectContainer />}>
          <Route path={":projectId"} element={<ConversationView />} />
          <Route path={":projectId/t/:threadId"} element={<ConversationView />} />
        </Route>
        <Route path={"projects/:projectId/edit"} element={<ProjectSettingsView />} />
        <Route path={"activity"} element={<ConversationManager />} />
        <Route path={"activity/dashboard"} element={<ConversationManager />} />
        <Route path={"activity/threads/:threadId"} element={<ConversationManager />} />
        <Route path={"*"} element={<Navigate to={"/projects"} />} />  {/* TODO: Create a home page. */}
      </Route>
    </DomRoutes>
  )
}
