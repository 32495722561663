import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2"
import Project from "../types/Project";
import ProjectCard, {AddProjectCard} from "../components/ProjectCard";
import {useNavigate} from "react-router-dom";
import useProjects from "../api/useProjects";
import NoProjectsBanner from "../components/NoProjectsBanner";
import GuidedProjectSettingsDialog from "../components/GuidedProjectSettingsDialog";
import useUserContext from "../contexts/useUserContext";
import {
  Box,
  Button,
  Container, IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import {DateUtils} from "../utils";
import User from "../types/User";
import useUser from "../api/useUser";
import {GridViewRounded, TableRowsRounded, Add as AddIcon} from "@mui/icons-material";
import ProjectTemplates from "../components/ProjectTemplates";

const ProjectsView = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { user, authenticationStatus } = useUserContext()
  const { projects } = useProjects()
  const { list } = useUser()

  const [isGuidedTourOpen, setIsGuidedTourOpen] = useState(false)
  const [templateDefaults, setTemplateDefaults] = useState<Partial<Project>>()
  const [otherUsers, setOtherUsers] = useState<User[]>([])
  const [viewMode, setViewMode] = useState<"grid"|"table">(localStorage.getItem("projectsViewMode") as "grid"|"table" || "grid")

  const cardBreakpoints = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3,
    xl: 2,
  }

  const findUser = (user_id: string) => {
    return otherUsers.find(u => u._id === user_id)
  }

  const toggleViewMode = (mode: "grid"|"table") => {
    setViewMode(mode)
    localStorage.setItem("projectsViewMode", mode)
  }

  useEffect(() => {
    if (authenticationStatus === "authenticated") {
      list().then((resp) => {
        setOtherUsers(resp.data)
      })
    }
  }, [authenticationStatus]);

  if (!projects) {
    return <></>
  }

  const gridView = () => (
    <Grid container spacing={2}>
      {projects.length === 0 &&
        <Grid xs={12}>
          <NoProjectsBanner onClick={() => setIsGuidedTourOpen(true)} />
        </Grid>
      }
      {projects.map((project) => (
        <Grid key={project._id} {...cardBreakpoints} >
          <ProjectCard
            project_id={project._id}
            compact
            title={project.name}
            subtitle={project.description || "..."}
            onClick={() => navigate(`/projects/${project._id}`)}
          />
        </Grid>
      ))}
      <ProjectTemplates cardBreakpoints={cardBreakpoints} setIsGuidedTourOpen={setIsGuidedTourOpen} setTemplateDefaults={setTemplateDefaults} />
    </Grid>
  )

  const tableView = () => (
    <TableContainer component={Paper} elevation={0} sx={{ background: theme.palette.surface.main }}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Project</TableCell>
            <TableCell>Creator</TableCell>
            <TableCell>Visibility</TableCell>
            <TableCell>Last Update</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project) => (
            <TableRow
              hover
              key={project._id}
              sx={{
                "td, th": { border: 0 },
              }}
              onClick={() => navigate(`/projects/${project._id}`)}
              style={{ cursor: "pointer" }}
            >
              <TableCell>
                {project.name}
              </TableCell>
              <TableCell>{project.creator_user_id === user?._id ? "You" : (findUser(project.creator_user_id)?.first_name || "User")}</TableCell>
              <TableCell>{project.visibility_setttings.charAt(0).toUpperCase() + project.visibility_setttings.slice(1)}</TableCell>
              <TableCell>{DateUtils.getDateString(new Date(project.created_at), true)}</TableCell>
            </TableRow>
          ))}
          <TableRow
            hover
            onClick={() => setIsGuidedTourOpen(true)}
            sx={{
              "td, th": { border: 0 },
            }}
            style={{ cursor: "crosshair"}}
          >
            <TableCell colSpan={4}>
              <Typography variant={"body2"} color={"primary"}>Create new project</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (<>
    <Container maxWidth={false}>
      <Box sx={{ flexShrink: 0, display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }}>
        <Typography variant={"h6"}>Projects</Typography>
        {projects.length > 0 &&
          <Button sx={{ml: 2}} size="small" variant="outlined"
            startIcon={<AddIcon/>} onClick={() => setIsGuidedTourOpen(true)}
          >
              New Project
          </Button>
        }
        <div style={{ flexGrow: 1 }} />
        <Tooltip title={"View Mode"} arrow>
          <IconButton
            onClick={() => toggleViewMode(viewMode === "grid" ? "table" : "grid")}
            size={"large"}
            color={"inherit"}
            edge={"end"}
          >
            {viewMode === "table" ? <GridViewRounded/> : <TableRowsRounded />}
          </IconButton>
        </Tooltip>
      </Box>
      {viewMode === "table" ? tableView() : gridView()}
    </Container>
    <GuidedProjectSettingsDialog open={isGuidedTourOpen} onClose={() => {
      setIsGuidedTourOpen(false)
      setTemplateDefaults(undefined)
    }} defaultFields={templateDefaults} isCancellable />
  </>)
}

export default ProjectsView;
