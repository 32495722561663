import axios from "axios";
import Project, {ProjectPermission, ProjectRole, UserProjectPermission} from "../types/Project";
import {createContext, useContext, useEffect, useState} from "react";
import useUserContext from "../contexts/useUserContext";
import useSearch from "./useSearch";

interface ProjectsContextType {
  projects: undefined | Project[],
  invitedProjects: undefined | Project[],
  getProject: (projectId: string) => Promise<{ data: Project }>,
  createProject: (project: Partial<Project>) => Promise<{ data: Project }>,
  updateProject: (project: Partial<Project>) => Promise<{ data: Project }>,
  deleteProject: (projectId: string) => Promise<{ data: Project }>,
  generateProjectDescription: (projectName: string) => Promise<{ data: { output: string }}>,
  projectPermissions: (projectId: string) => UserProjectPermission[],
  addProjectPermissions: (projectId: string, permissions: ProjectPermission[]) => Promise<{ data: UserProjectPermission[] }>,
  updateProjectPermission: (projectId: string, permissionId: string, projectRole: ProjectRole) => Promise<{ data: UserProjectPermission }>,
  deleteProjectPermission: (projectId: string, permissionId: string) => Promise<{ data: UserProjectPermission }>,
  acceptProjectInvitation: (projectId: string, permissionId: string) => Promise<{ data: UserProjectPermission }>,
}


const ProjectsContext = createContext<ProjectsContextType>({
  projects: undefined,
  invitedProjects: undefined,
  // @ts-ignore
  getProject: (projectId: string) => Promise.resolve({data: {}}),
  // @ts-ignore
  createProject: (project: Partial<Project>) => Promise.resolve({data: {}}),
  // @ts-ignore
  updateProject: (project: Partial<Project>) => Promise.resolve({data: {}}),
  // @ts-ignore
  deleteProject: (projectId: string) => Promise.resolve({data: {}}),
  generateProjectDescription: (projectName: string) => Promise.resolve({data: {output: ""}}),
  projectPermissions: (projectId: string) => [],
  addProjectPermissions: (projectId: string, permissions: ProjectPermission[]) => Promise.resolve({data: []}),
  // @ts-ignore
  updateProjectPermission: (projectId: string, permissionId: string, projectRole: ProjectRole) => Promise.resolve({data: {}}),
  // @ts-ignore
  deleteProjectPermission: (projectId: string, permissionId: string) => Promise.resolve({data: {}}),
  // @ts-ignore
  acceptProjectInvitation: (projectId: string, permissionId: string) => Promise.resolve({data: {}}),
})

export const ProjectsProvider = ({children}: { children: React.ReactNode }) => {
  const user = useUserContext()
  const { query } = useSearch()

  const [storedProjects, setStoredProjects] = useState<Project[]>()
  const [storedInvitedProjects, setStoredInvitedProjects] = useState<Project[]>()
  const [storedProjectPermissions, setStoredProjectPermissions] = useState<{ [projectId: string]: UserProjectPermission[] }>({})

  const listProjects = async (): Promise<{ data: Project[] }> => {
    if (storedProjects && storedProjects.length > 0) {
      return Promise.resolve({data: storedProjects})
    }
    const response = await axios.get("/api/projects");
    setStoredProjects(response.data);
    return response;
  }

  const listInvitedProjects = async (): Promise<{ data: Project[] }> => {
    if (storedInvitedProjects && storedInvitedProjects.length > 0) {
      return Promise.resolve({data: storedInvitedProjects})
    }
    const response = await axios.get("/api/invited-projects");
    setStoredInvitedProjects(response.data);
    return response;
  }

  const getProject = (projectId: string): Promise<{ data: Project }> => {
    const project = storedProjects?.find((p) => p._id === projectId)
    if (project) {
      return Promise.resolve({data: project})
    }
    return axios.get(`/api/projects/${projectId}`)
  }

  const createProject = async (project: Partial<Project>): Promise<{ data: Project }> => {
    const response = await axios.post(`/api/projects`, project);
    setStoredProjects([...storedProjects || [], response.data]);
    return response;
  }

  const updateProject = async (project: Partial<Project>): Promise<{ data: Project }> => {
    const response = await axios.put(`/api/projects/${project._id}`, project);
    let updatedProjects = storedProjects?.map((p) => p._id === project._id ? response.data : p);
    setStoredProjects(updatedProjects);
    return response;
  }

  const deleteProject = async (projectId: string): Promise<{ data: Project }> => {
    const response = await axios.delete(`/api/projects/${projectId}`);
    let updatedProjects = storedProjects?.filter((p) => p._id !== projectId);
    setStoredProjects(updatedProjects);
    return response;
  }

  const generateProjectDescription = (projectName: string): Promise<{ data: { output: string }}> => query(`This new chatbot is about ${projectName}. Generate a short user-friendly blurb for it for the end users based on the title about what it can help with. Don't say the words chatbot or AI. Don't give an introduction.`)

  const listProjectPermissions = async (projectId: string): Promise<{ data: UserProjectPermission[] }> => {
    if (storedProjectPermissions[projectId]) {
      return Promise.resolve({data: storedProjectPermissions[projectId]})
    }
    const response = await axios.get(`/api/projects/${projectId}/permissions`);
    setStoredProjectPermissions({...storedProjectPermissions, [projectId]: response.data});
    return response;
  }

  const getProjectPermissions = (projectId: string): UserProjectPermission[] => {
    if (!storedProjectPermissions[projectId] || storedProjectPermissions[projectId].length === 0) {
      listProjectPermissions(projectId)
    }
    return storedProjectPermissions[projectId] || []
  }
  const addProjectPermissions = async (projectId: string, permissions: ProjectPermission[]): Promise<{ data: UserProjectPermission[] }> => {
    const response = await axios.post(`/api/projects/${projectId}/permissions`, permissions)
    setStoredProjectPermissions({...storedProjectPermissions, [projectId]: response.data})
    return response
  }
  const updateProjectPermission = (projectId: string, permissionId: string, projectRole: ProjectRole): Promise<{ data: UserProjectPermission }> => {
    const response = axios.post(`/api/projects/${projectId}/permissions/${permissionId}`, { role: projectRole })
    setStoredProjectPermissions({
      ...storedProjectPermissions,
      [projectId]: storedProjectPermissions[projectId].map((p) => p.permission._id === permissionId ? {...p, role: projectRole} : p)
    })
    return response
  }
  const acceptProjectInvitation = async (projectId: string, permissionId: string): Promise<{ data: UserProjectPermission }> => {
    const inviteResponse = await axios.post(`/api/projects/${projectId}/permissions/${permissionId}/accept`, {})
    const response = await axios.get("/api/projects");
    setStoredProjects(response.data);
    setStoredInvitedProjects(storedInvitedProjects?.filter((p) => p._id !== projectId));
    return inviteResponse
  }
  const deleteProjectPermission = (projectId: string, permissionId: string): Promise<{ data: UserProjectPermission }> => {
    const response = axios.delete(`/api/projects/${projectId}/permissions/${permissionId}`)
    setStoredProjectPermissions({
      ...storedProjectPermissions,
      [projectId]: storedProjectPermissions[projectId].filter((p) => p.permission._id !== permissionId)
    })
    return response
  }

  useEffect(() => {
    if (user.authenticationStatus === "authenticated") {
      listProjects()
      listInvitedProjects()
    }
  }, [user.authenticationStatus])

  return (
    <ProjectsContext.Provider value={{
      projects: storedProjects,
      invitedProjects: storedInvitedProjects,
      getProject,
      createProject,
      updateProject,
      deleteProject,
      generateProjectDescription,
      projectPermissions: getProjectPermissions,
      addProjectPermissions,
      updateProjectPermission,
      deleteProjectPermission,
      acceptProjectInvitation,
    }}>
      {children}
    </ProjectsContext.Provider>
  )
}

const useProjects = () => {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error('useProjectsContext must be used within a ProjectsProvider');
  }
  return context;
}

export default useProjects
