import axios from "axios";
import Search from "../types/Search";
import {UserType} from "../types/ConversationThreadMessage";

const useSearch = () => {

  const search = (projectId: string, query: string, threadId?: string | undefined): Promise<{ data: { output: string, message_id: string, thread_id: string }}> => axios.get(
    "/api/search",
    {
      params: {
        project_id: projectId,
        thread_id: threadId,
        query
      }
    })

  /** Streams the search results from the backend.
   *
   * To use: `for await (const data of searchStream(projectId, query, threadId)) { ... }`
   *
   * */
  const searchStream = async function* (projectId: string, query: string, threadId?: string): AsyncGenerator<string | any> {
    let url = `/api/search-stream?query=${encodeURIComponent(query)}&project_id=${projectId}`
    if (threadId) {
      url += `&thread_id=${threadId}`
    }

    const response = await fetch(url)

    if (!response.body) {
      console.log("Fetch response body is null.")
      return
    }

    const reader = response.body.getReader()

    while (true) {
      const { done, value } = await reader.read()
      if (done) {
        return
      }
      const text = new TextDecoder("utf-8").decode(value)
      if (text.startsWith('JSON_RESPONSE')) {
        try {
          let payload = text.replace('JSON_RESPONSE:','');
          let data = JSON.parse(payload)
          console.log('data', data)
          yield data
        } catch (e) {
          console.log(e);
          console.log(text)
        }
      } else {
        yield text
      }
    }
  }

  const query = (query: string): Promise<{ data: Search }> => axios.get(
    "/api/query",
    {
      params: {
        query
      }
    })

  return {
    search,
    searchStream,
    query
  }
}

export default useSearch
