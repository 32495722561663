import User from "./types/User";
import * as TablarIcons from "@tabler/icons-react"
import React from "react";

export namespace DateUtils {
  export const getDateString = (date: Date, includeYear: boolean = false) => {
    const month = date.getMonth() + 1
    const day = date.getDate()
    const year = date.getFullYear()

    const monthString = month === 1 ? "Jan" : month === 2 ? "Feb" : month === 3 ? "Mar" : month === 4 ? "Apr" : month === 5 ? "May" : month === 6 ? "Jun" : month === 7 ? "Jul" : month === 8 ? "Aug" : month === 9 ? "Sep" : month === 10 ? "Oct" : month === 11 ? "Nov" : "Dec"

    const yearPart = includeYear ? `, ${year}` : ""

    return `${monthString} ${day}${yearPart}`
  }

  export const getTimeString = (date: Date) => {
    const hours = date.getHours()
    const minutes = date.getMinutes()

    return `${hours > 12 ? hours - 12 : hours}:${minutes < 10 ? "0" + minutes : minutes} ${hours > 12 ? "PM" : "AM"}`
  }
}

export namespace StringUtils {
  export function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  export function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  export function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      };
    }

    return null; // Invalid hex input
  }
}

export namespace UserUtils {
  export function getFullName(user: Partial<User>) {
    return (user.first_name && user.last_name) ? `${user.first_name} ${user.last_name}` : `User`
  }
}

export namespace IconUtils {
  export function getTablarIcon(iconName: string) {
    // @ts-ignore
    const icon = TablarIcons[iconName];
    if (!icon) {
      return null;
    }
    return React.createElement(icon);
  };
}
