import React from "react";
import PDFPreviewDialog, {PageType} from "../components/reusable/PDFPreviewDialog";


const Playground = () => {

  const documentId = "6540451995a52e1cf0fc90c1"  // 1

  const pages: PageType[] = [
    {
      number: 1,
      text: "Polarization and Controversy: Trump's presidency was marked by deep political polarization,"
    },
    {
      number: 0,
      text: "Donald Trump served as the 45th President of the United States from January 20, 2017"
    }
  ]

  return (
    <PDFPreviewDialog
      open={true}
      documentTitle={"trump.pdf"}
      documentId={documentId}
      pages={pages}
    />
  )
}


export default Playground
