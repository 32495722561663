import {
  Dialog,
  DialogContent,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import React, {useCallback, useEffect, useMemo, useState} from "react";
import useDocuments from "../../api/useDocuments";
import {useSnack} from "../../contexts/useSnackContext";
import DialogTitle from "@mui/material/DialogTitle";
import {Document, Page } from "react-pdf";
import {CloseRounded} from "@mui/icons-material";


function highlightPattern(text: string, pattern: string) {
  return text.replace(pattern, (value) => `<mark>${value}</mark>`);
}

export interface PageType {
  number: number
  text?: string
}

export interface DocumentType {
  documentId: string
  documentTitle: string
  pages: PageType[]
}

interface PDFPreviewDialogProps extends DocumentType {
  open: boolean
  onClose?: () => void
}

const PDFPreviewDialog = ({ open, onClose, documentTitle, documentId, pages }: PDFPreviewDialogProps) => {
  const snackbar = useSnack()
  const theme = useTheme()
  const shouldFullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const { viewDocument } = useDocuments()

  const [document, setDocument] = useState<Blob|null>(null)
  const [documentURL, setDocumentURL] = useState<string|null>(null)
  const [currentPage, setCurrentPage] = useState(1)

  const sortedPages = useMemo(() => pages.sort((a, b) => a.number - b.number), [pages])

  const textRenderer = useCallback((text: any) => highlightPattern(text.str, pages[currentPage - 1].text || ""), [currentPage])

  useEffect(() => {
    if (!open) return
    if (documentURL) URL.revokeObjectURL(documentURL)  // To prevent a memory leak per gpt.
    setDocumentURL(null)
    viewDocument(documentId)
      .then((res) => {
        setDocumentURL(URL.createObjectURL(res.data))
      })
      .catch((err) => {
        console.log(err)
        snackbar.addErrorMessage("Error loading document.")
      })

      return () => {
        if (documentURL) {
          URL.revokeObjectURL(documentURL)
        }
      }
  }, [documentId, open])

  const message = (text: string) => (
    <Typography
      variant={"h6"}
      sx={{
        position: "fixed",
        left: "50%",
        top: "50%" ,
        transform: "translate(-50%, -50%)"
    }}>
      {text}
    </Typography>
  )


  return (
    <Dialog open={open} onClose={onClose} fullWidth fullScreen={shouldFullScreen}>
      <DialogTitle sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <span>{documentTitle}</span>
        <div style={{ flexGrow: 1 }}/>
        <IconButton
          size={"large"}
          edge={"end"}
          color={"inherit"}
          onClick={onClose}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Tabs value={currentPage - 1}>
        {sortedPages.map((page) => (
          <Tab key={page.number} label={`Page ${page.number + 1}`} onClick={() => setCurrentPage(page.number + 1)} />
        ))}
      </Tabs>
      <DialogContent sx={{ p: { xs: 0 }, ...(shouldFullScreen ? {} : { height: 800 }) }}>
        <Document
          file={documentURL}
          noData={message("No document provided")}
          error={message("Error loading document")}
          loading={message("Loading...")}
        >
          <Page
            pageNumber={currentPage}
            customTextRenderer={pages[currentPage - 1].text ? textRenderer : undefined}
            width={shouldFullScreen ? undefined : 600}
            loading={message("Loading...")}
          />
        </Document>
      </DialogContent>
    </Dialog>
  )
}

export default PDFPreviewDialog
