import React, {useMemo} from 'react';
import './App.css';
import "@fontsource/poppins";
import {createTheme, CssBaseline, ThemeProvider, useMediaQuery} from "@mui/material";

import {BrowserRouter} from "react-router-dom";
import Routes from "./Routes";
import theme, {componentTheme, getDesignTokens} from "./theme";
import {UserProvider} from "./contexts/useUserContext";
import {ProjectsProvider} from "./api/useProjects";
import {SnackProvider, SnackbarList} from "./contexts/useSnackContext";
import {ProjectContainerProvider} from "./contexts/useProjectContainerContext";

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const variableTheme = useMemo(() => {
    let newTheme = createTheme(getDesignTokens(prefersDarkMode ? 'dark' : 'light', theme))
    return componentTheme(newTheme)
  }, [prefersDarkMode])

  return (
    <BrowserRouter>
      <ThemeProvider theme={variableTheme}>
        <CssBaseline />
        <UserProvider>
          <ProjectContainerProvider>
            <SnackProvider>
              <ProjectsProvider>
                <SnackbarList/>
                <Routes />
              </ProjectsProvider>
            </SnackProvider>
          </ProjectContainerProvider>
        </UserProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
