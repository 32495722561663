import {createContext, ReactNode, useContext, useState} from "react";

interface ProjectContainerType {
  open: boolean
  toggleOpen: (open: boolean) => void
}

const ProjectContainerContext = createContext<ProjectContainerType>({
  open: true,
  toggleOpen: () => {},
})

export const ProjectContainerProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState<boolean>(true)

  const handleToggleOpen = (open: boolean) => {
    setOpen(open)
  }

  return (
    <ProjectContainerContext.Provider value={{open, toggleOpen: handleToggleOpen}}>
      {children}
    </ProjectContainerContext.Provider>
  )
}

const useProjectContainerContext = () => {
  const context = useContext(ProjectContainerContext)
  if (!context) {
    throw new Error('useProjectContainerContext must be used within a ProjectContainerProvider');
  }
  return context;
}

export default useProjectContainerContext
