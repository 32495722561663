import User from "./User"

export default interface Project {
  created_at: Date
  creator_user_id: string
  name: string
  description?: string
  agent_instructions?: string
  visibility_setttings: string  // TODO: Define this w/ Aaron.
  user_permissions?: ProjectPermission[]
  current_user_permission?: ProjectPermission
  _id: string
}

export interface ProjectPermission {
  _id: string,
  email: string,
  role: ProjectRole,
  user_id?: string,
  invited_by_email?: string,
}

export interface UserProjectPermission {
  permission: ProjectPermission,
  user?: User,
}

export enum ProjectRole {
  owner = "owner",
  editor = "editor",
  viewer = "viewer",
}
