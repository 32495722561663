import {createTheme, PaletteMode, Theme} from "@mui/material";

// Augment the palette to include a surface color
declare module '@mui/material/styles' {
  interface Palette {
    surface: Palette['primary'];
  }

  interface PaletteOptions {
    surface?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Card' {
  interface CardPropsColorOverrides {
    surface: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    pureText: true;
  }
}

export const getDesignTokens = (mode: PaletteMode, theme: Theme) => ({
  ...theme,
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        primary: {
          main: '#007BFF',
          contrastText: '#FFFFFF',
        },
        secondary: {
          main: '#FFC759',
          light: "#FFF8E1",
          contrastText: '#000000',
        },
        background: {
          default: '#ffffff',
        },
        surface: theme.palette.augmentColor({
          color: {
            dark: "#f1f1f1",
            main: "#f8f8f8"
          },
          name: "surface",
        })
      }
      : {
        primary: {
          main: '#FFC759',
          contrastText: '#000000',
        },
        secondary: {
          main: '#007BFF',
          light: "#E4F5FF",
          contrastText: '#ffffff',
        },
        background: {
          default: '#000000',
        },
        surface: theme.palette.augmentColor({
          color: {
            main: "#121212"
          },
          name: "surface",
        })
      })
  }
})

let theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      'sans-serif',
    ].join(','),
    fontWeightRegular: 400,
    h1: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.15rem"
    },
    h6: {
      fontWeight: 600,
      fontSize: "1rem",
    }
  }
})

export const componentTheme = (theme: Theme) => createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "48px",
          textTransform: "none",
        },
      },
      defaultProps: {
        disableElevation: true,
        variant: "contained",
      },
      variants: [
        {
          props: { variant: "pureText" },
          style: {
            color: theme.palette.primary.main,
            padding: 0,
            // "&:hover": {
            //   fontWeight: "bold"
            // }
          }
        }
      ]
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          background: theme.palette.surface.main
        }
      },
      defaultProps: {
        elevation: 0,
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            background: "transparent"
          }
        }
      ]
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "12px",
          [theme.breakpoints.down("sm")]: {
            borderRadius: "0px",
          }
        }
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          borderRadius: "48px",
          textTransform: "none",
        },
      },
      defaultProps: {
        disableElevation: true,
        variant: "contained",
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('xs')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          }
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('xs')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            borderRadius: 8,
          }
        }
      }
    },
  },
})

export default theme;
