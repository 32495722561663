import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import useProjects from "../../api/useProjects";
import Project from "../../types/Project";
import {useSnack} from "../../contexts/useSnackContext";
import {
  Box,
  Container, IconButton,
  Menu, MenuItem, Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import ConversationThreadContainer from "../../components/reusable/ConversationThreadContainer";
import useUserContext from "../../contexts/useUserContext";
import AvatarPermissionsGroup from "../../components/reusable/AvatarPermissionsGroup";
import DocumentsContainer from "./DocumentsContainer";
import {
  FolderRounded,
  NotesRounded,
  MoreVert as MoreVertIcon
} from "@mui/icons-material";
import useProjectContainerContext from "../../contexts/useProjectContainerContext";
import DeleteThreadDialog from "../../components/DeleteThreadDialog";

const ConversationView = () => {
  const navigate = useNavigate()
  const snackbar = useSnack()
  const theme = useTheme()

  const { projectId, threadId } = useParams()
  const { getProject  } = useProjects()
  const { open, toggleOpen } = useProjectContainerContext()

  const [project, setProject] = useState<Project | null>(null)
  const [isDocumentListOpen, setIsDocumentListOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  useEffect(() => {
    if (projectId) {
      getProject(projectId)
        .then((response) => {
          setProject(response.data)
        })
        .catch((err) => {
          console.log(err)
          snackbar.addErrorMessage("Failed to get project.")
        })
    }
  }, [projectId]);


  const menu = (
    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={() => {
        setIsDocumentListOpen(true)
        setAnchorEl(null)
      }}>
        View Documents
      </MenuItem>
      <MenuItem onClick={() => {
        navigate(`/projects/${projectId}/edit`)
        setAnchorEl(null)
      }}>
        Settings
      </MenuItem>
    </Menu>
  )

  return (
    <Container
      maxWidth={"md"}
      sx={{
        height: "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        pb: 2
      }}
    >
      <Box sx={{ flexShrink: 0, display: "flex", flexDirection: "row", alignItems: "center" }}>
        <IconButton
          onClick={() => toggleOpen(!open)}
          size={"large"}
          color={"inherit"}
          edge={"start"}
          sx={{ mr: 1 }}
        >
          <NotesRounded />
        </IconButton>
        <Typography variant={"h6"} sx={{ flexShrink: 0 }}>
          {project?.name}
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <Box sx={{ flexShrink: 0 }}>
          {projectId && <AvatarPermissionsGroup projectId={projectId}/>}
          <IconButton
            onClick={(e) => setIsDocumentListOpen(prev => !prev)}
            size={"large"}
            color={"inherit"}
            edge={"end"}
          >
            <FolderRounded />
          </IconButton>
          <IconButton
            onClick={(e) => setMenuAnchorEl(e.currentTarget)}
            size={"large"}
            color={"inherit"}
            edge={"end"}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            open={Boolean(menuAnchorEl)}
            anchorEl={menuAnchorEl} onClose={() => setMenuAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={() => {
              setMenuAnchorEl(null)
              setShowDeleteDialog(true)
            }}>
              <Typography variant="inherit">
                Delete Thread
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => {
                navigate(`/projects/${projectId}/edit`)
              }}>
                <Typography variant="inherit">
                  Project Settings
                </Typography>
              </MenuItem>
          </Menu>
        </Box>
      </Box>
      {projectId && (
        <ConversationThreadContainer
          projectId={projectId}
          projectName={project?.name}
          projectDescription={project?.description}
          threadId={threadId}
        />
      )}
      {projectId && <DocumentsContainer projectId={projectId} open={isDocumentListOpen} onClose={() => setIsDocumentListOpen(false)} />}
      {menu}
      {(projectId && threadId) && (
        <DeleteThreadDialog
          projectId={projectId}
          threadId={threadId}
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
        />
      )}
    </Container>
  )
}

export default ConversationView
