import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

const LLMs: { [modelName: string]: { label: string, enabled: boolean } } = {
  "gpt-3.5-turbo": {
    label: "GPT-3.5 Turbo (Enterprise Secure)",
    enabled: true
  },
  "gpt-4": {
    label: "GPT-4 (Enterprise Secure)",
    enabled: true
  },
  "vertex-2": {
    label: "Vertex AI, Palm 2 (Google Cloud)",
    enabled: false
  },
  "anthropic": {
    label: "Anthropic",
    enabled: false
  },
  "llama": {
    label: "Llama",
    enabled: false
  },
  "custom": {
    label: "Custom Fine-Tuned Model",
    enabled: false
  }
}

interface LLMSelectProps {
  value: string
  onChange: (value: string) => void
}

const LLMSelect = (props: LLMSelectProps) => (
  <FormControl fullWidth>
    <InputLabel id={"llm-select"}>Large Language Model</InputLabel>
    <Select
      labelId={"llm-select"}
      value={props.value}
      label={"Large Language Model"}
      onChange={(e) => props.onChange(e.target.value)}
    >
      {Object.entries(LLMs).map(([key, llmProps]) => (
        <MenuItem key={key} value={key} disabled={!llmProps.enabled}>{llmProps.label}</MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default LLMSelect
