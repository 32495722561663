import React, {useEffect, useState, useRef, ReactNode} from "react";

import {useNavigate,useParams,useLocation} from "react-router-dom";

import {
  Send as SendIcon
} from "@mui/icons-material";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import useUserContext from "../../contexts/useUserContext";
import useConversation from "../../api/useConversationThread";
import ConversationThreadMessage, {UserType} from "../../types/ConversationThreadMessage";
import ConversationThread, { ConversationThreadStatus } from "../../types/ConversationThread";
import useSearch from "../../api/useSearch";
import {useSnack} from "../../contexts/useSnackContext";
import UserMessage from "./message/UserMessage";
import AIMessage from "./message/AIMessage";
import {Avatar, ListItem, ListItemAvatar, ListItemText, Skeleton} from "@mui/material";
import {DocumentType} from "./PDFPreviewDialog";
import Search from "../../types/Search";


interface ConversationThreadProps {
  projectId: string;
  threadId?: string;

  projectName?: string;
  projectDescription?: string;
}

const ConversationThreadContainer = ({ projectId, threadId, projectName, projectDescription }: ConversationThreadProps) => {
  const [activeThreadId, setActiveThreadId] = useState<string | undefined>(threadId)
  const [activeThread, setActiveThread] = useState<ConversationThread | undefined>()
  const [loadingMessages, setLoadingMessages] = useState(false)
  const [messages, setMessages] = useState<Partial<ConversationThreadMessage>[]>([])

  const [currentStep, setCurrentStep] = useState<string | null>(null)
  const [incomingMessage, setIncomingMessage] = useState<string | null>(null)

  const [newQueryString, setNewQueryString] = useState("")

  const location = useLocation()
  const params = useParams<{threadId: string}>()
  const navigate = useNavigate()
  const snackbar = useSnack()

  const containerRef = useRef(null)
  const searchRef = useRef(null);

  const { user } = useUserContext()
  const { listThreadMessages, getThread } = useConversation()
  const { search, searchStream } = useSearch()

  useEffect(() => {
    if (threadId) {
      setActiveThreadId(threadId)
    } else {
      setActiveThreadId(undefined)
    }
    setNewQueryString("")
  }, [threadId])

  useEffect(() => {
    if (activeThreadId) {
      getThread(activeThreadId)
        .then((response) => {
          setActiveThread(response.data)
        })
        .catch((err) => {
          console.log(err)
          snackbar.addErrorMessage("Error loading thread.")
        })
    } else {
      setActiveThread(undefined)
      if (!activeThreadId && searchRef.current) {
        // @ts-ignore
        searchRef.current.focus();
      }
    }
  }, [activeThreadId])

  useEffect(() => {
    if (threadId && activeThreadId && !loadingMessages) {
      setLoadingMessages(true)
      setMessages([])
      listThreadMessages(activeThreadId)
        .then((response) => {
          setMessages(response.data)
        })
        .catch((err) => {
          console.log(err)
          snackbar.addErrorMessage("Error loading thread messages.")
        })
        .finally(() => setLoadingMessages(false))
    } else if (activeThreadId && !threadId) {
      if (!params.threadId) {
        navigate(`${location.pathname}/t/${activeThreadId}`)
      }
    } else if (!threadId && !activeThreadId) {
      setMessages([])
    }
  }, [activeThreadId]);

  /**
   * Submits a query to the backend.
   *
   * @param internalQuery - If provided, this query will be used instead of the newQueryString.
   *
   * */
  const handleSearch = (internalQuery?: string) => {
    let q = internalQuery || newQueryString
    if (q.trim() === "") return
    setNewQueryString("")
    setMessages([
      ...messages,
      { body: q, user_type: UserType.user, user: user } as Partial<ConversationThreadMessage>,
    ])
    // if llm / agent type supports stream, do stream
    handleStreamSearch(q)
    // else, do basic
    // handleSyncSearch(q)
  }

  const handleSyncSearch = (q: string) => {
    search(projectId, q, activeThreadId)
      .then((response) => {
        setMessages((prevQueries) => [...prevQueries.slice(0,-1), {
          // projectName: project?.name || "",
          // user,
          _id: response.data.message_id,
          thread_id: response.data.thread_id,
          body: response.data.output,
          user_type: UserType.system,
        }])
        if (!activeThreadId) {
          setActiveThreadId(response.data.thread_id)
        }
      })
      .catch((err) => {
        console.log(err)
        snackbar.addErrorMessage("Error processing query.", true)
      })
  }

  const handleStreamSearch = (query: string) => {
    const fetchData = async () => {
      let fullText = "";
      let storedActiveThreadId = ""
      let systemResponse: Search | undefined = undefined

      setIncomingMessage("")
      setCurrentStep("analyzing")

      for await (const chunk of searchStream(projectId, query, activeThreadId)) {
        if (typeof chunk !== 'string') {
          if (chunk.thread_id && !activeThreadId) {
            storedActiveThreadId = chunk.thread_id
          }
          if (chunk.action?.tool) {
            setCurrentStep(chunk.action.tool)
            setTimeout(() => {}, 1000)
          }
          if (chunk.steps) {
            systemResponse = chunk
          }
        } else {
          fullText += chunk;
          setIncomingMessage(fullText)
        }
      }

      if (!activeThreadId) {
        setActiveThreadId(storedActiveThreadId)
      }

      setCurrentStep(null)
      setIncomingMessage(null)
      setMessages((prev) => [...prev, {
        thread_id: storedActiveThreadId,
        body: fullText,
        user_type: UserType.system,
        system_response: systemResponse,
      }])
    };

    fetchData()
      .catch(error => {
        console.error("Stream error:", error);
        setCurrentStep(null);
        setIncomingMessage(null);
        snackbar.addErrorMessage("Error processing query.", true)
      })
  }

  useEffect(() => {
    if (containerRef.current) {
      // @ts-ignore
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [messages, incomingMessage])

  const messageList = (children: ReactNode) => {
    return (
    <Box
      ref={containerRef}
      sx={{
        flexGrow: 1,
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        mb: 2,
      }}
    >
      {!activeThreadId && (
        <Typography
          variant={"caption"}
          color={"text.secondary"}
          sx={{
            width: "90%",
            textAlign: "center",
            alignSelf: "center",
            m: 2,
          }}
        >
          {projectDescription}
        </Typography>
      )}
      {children}
    </Box>
  )}

  return (
    <>
      {messageList(<>
        {loadingMessages && (
          <ListItem disableGutters alignItems={"flex-start"}>
            <ListItemAvatar><Avatar/></ListItemAvatar>
            <ListItemText primary={"..."} secondary={<Skeleton animation={"wave"} width={"50%"} />} />
          </ListItem>
        )}
        {messages.map((message, index) => {
          const documentSteps = message.system_response?.steps?.filter((step) => step?.action?.tool === "load_documents")|| []
          const docs = documentSteps.map((step) => step.docs).flat() || []

          const docPages = docs.reduce((acc, doc) => {
            const docIndex = acc.findIndex((d) => d.documentId === doc.metadata.document_id)
            if (docIndex === -1) {
              acc.push({
                documentId: doc.metadata.document_id,
                documentTitle: doc.metadata.source.split("/").pop() || "",
                pages: [{ number: doc.metadata.page }],
              })
            } else {
              acc[docIndex].pages.push({ number: doc.metadata.page })
            }
            return acc
          } , [] as DocumentType[])

          return (
            message.user_type === UserType.system ? (
              <AIMessage
                key={index}
                name={projectName}
                message={message.body}
                documents={docPages}
              />
            ) : (
              <UserMessage
                key={index}
                user={message.user!}
                message={message.body!}
              />
            )
          )
        })}
        {incomingMessage !== null && (
          <AIMessage
            name={projectName}
            message={incomingMessage}
            step={currentStep || undefined}
            disableCopy
          />
        )}
        {activeThread && !loadingMessages &&
          <UpdateStatusPanel thread={activeThread} />
        }
      </>)}
      {/* disable query from other user */}
      {!(messages && messages.length > 0 && messages[0]?.user?._id !== user?._id) && (
        <TextField
          id={"queryField"}
          label={"Ask a question"}
          fullWidth
          multiline
          maxRows={4}
          inputRef={searchRef}
          autoComplete={"off"}
          value={newQueryString}
          onChange={(e) => setNewQueryString(e.target.value)}
          onKeyDown={(event) => event.key === "Enter" && handleSearch()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge={"end"}
                  onClick={() => handleSearch()}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          sx={{ flexShrink: 0 }}
        />
      )}
    </>
  )
}

const UpdateStatusPanel = ({ thread }: { thread: ConversationThread }) => {
  const { postThreadStatus } = useConversation()
  const [status, setStatus] = useState<ConversationThreadStatus | undefined>(thread.status)

  useEffect(() => {
    setStatus(thread.status)
  }, [thread])

  const handleChangeStatus = (newStatus: ConversationThreadStatus) => {
    setStatus(newStatus)
    postThreadStatus(thread._id, newStatus)
      .then((response) => {
        console.log(response)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <Box sx={{ py: 2, pl: 2, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
      <Typography sx={{mr: 2}} variant="caption">
        Did you get what you needed?
      </Typography>
      <Button
        onClick={() => handleChangeStatus(ConversationThreadStatus.success)}
        variant={status === ConversationThreadStatus.success ? "contained" : "outlined"}
        size={"small"}
        color={"success"} sx={{ mr: 2 }}
      >
          I got what I needed
      </Button>
      <Button
        onClick={() => handleChangeStatus(ConversationThreadStatus.help)}
        variant={status === ConversationThreadStatus.help ? "contained" : "outlined"}
        size={"small"}
        color={"error"}
      >
        I still need help
      </Button>
    </Box>
  )
}


export default ConversationThreadContainer
