import {AppBar, Toolbar} from "@mui/material";
import React from "react";

const SectionHeader = ({ children }: { children?: React.ReactNode}) => (
  <AppBar color={"transparent"} position={"static"} elevation={0} enableColorOnDark>
    <Toolbar>
      {children}
    </Toolbar>
  </AppBar>
)

export default SectionHeader;
