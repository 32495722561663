import React, {ReactNode, useState} from "react";
import {
  AppBar,
  Avatar,
  Button, IconButton,
  Link, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem,
  Toolbar, useMediaQuery,
  useTheme
} from "@mui/material";
import {
  AccountCircle,
  BarChartRounded,
  BlurOnRounded,
  CloseRounded,
  LogoutRounded,
  MenuRounded
} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import Logo from "../Logo";
import { InvitedProjectsIndicator } from "../../InvitedProjects";
import useUserContext from "../../contexts/useUserContext";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

const AppHeader = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { user } = useUserContext()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const navigateTo = (path: string) => {
    navigate(path)
    setIsMobileMenuOpen(false)
  }

  const temporaryAccountMenu = (
    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
      <MenuItem
        component={Link}
        href={"/auth/logout"}
      >
        Logout
      </MenuItem>
    </Menu>
  )

  const computerMenu = () => (
    <>
      <Button
        variant={"text"}
        color={location.pathname.includes("projects") ? "primary" : "inherit"}
        onClick={() => navigate("/projects")}
        startIcon={<BlurOnRounded />}
        sx={{ mx: 2 }}
      >
        Projects
      </Button>
      <Button
        variant={"text"}
        color={location.pathname.includes("activity") ? "primary" : "inherit"}
        onClick={() => navigate("/activity")}
        startIcon={<BarChartRounded />}
      >
        Activity
      </Button>
      <div style={{ flexGrow: 1 }} />
      <InvitedProjectsIndicator/>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant={"text"}
        color={"inherit"}
        startIcon={<Avatar sx={{ width: 24, height: 24 }} src={user?.picture}><AccountCircle /></Avatar>}
        sx={{ ml: 2 }}
      >
        {user?.first_name || "Account"}
      </Button>
    </>
  )

  const mobileMenu = () => (
    <Dialog open={isMobileMenuOpen} fullScreen>
      <DialogTitle>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Logo />
          <div style={{ flexGrow: 1 }} />
          <IconButton
            color={"inherit"}
            edge={"end"}
            onClick={() => setIsMobileMenuOpen(false)}>
            <CloseRounded />
          </IconButton>
        </div>
      </DialogTitle>
      <List>
        <ListItemButton
          selected={location.pathname.includes("projects")}
          onClick={() => navigateTo("/projects")}
        >
          <ListItemIcon>
            <BlurOnRounded
              color={location.pathname.includes("projects") ? "primary" : "inherit"}
            />
          </ListItemIcon>
          <ListItemText primary={"Projects"} />
        </ListItemButton>
        <ListItemButton
          selected={location.pathname.includes("activity")}
          onClick={() => navigateTo("/activity")}
        >
          <ListItemIcon>
            <BarChartRounded
              color={location.pathname.includes("activity") ? "primary" : "inherit"}
            />
          </ListItemIcon>
          <ListItemText primary={"Activity"} />
        </ListItemButton>
      </List>
      <div style={{ flexGrow: 1 }} />
      <List>
        <ListItemButton
          component={Link}
          href={"/auth/logout"}
        >
          <ListItemIcon>
            <LogoutRounded />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItemButton>
      </List>
    </Dialog>
  )

  return (<>
    <AppBar elevation={0} sx={{
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
    }}>
      <Toolbar sx={{ display: "flex", flexDirection: "row" }}>
        <Logo />
        {isMobile ? mobileMenu() : computerMenu()}
        {isMobile && (<>
          <div style={{ flexGrow: 1 }} />
          <InvitedProjectsIndicator/>
          <IconButton
            color={"inherit"}
            edge={"end"}
            onClick={() => setIsMobileMenuOpen(true)}>
            <MenuRounded />
          </IconButton>
        </>)}
      </Toolbar>
    </AppBar>
    <Toolbar />
    {children}
    {temporaryAccountMenu}
    {mobileMenu()}
  </>)
}

export default AppHeader
