import React, {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {Link, useParams} from 'react-router-dom'
import {AutoGraph as AutoGraphIcon, AutoGraphRounded, CloseRounded, NotesRounded} from "@mui/icons-material";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import SectionHeader from "../components/SectionHeader";
import ActivityDashboard from "./ActivityDashboard";
import ConversationThreadList from "../components/reusable/ConversationThreadList";
import Project from "../types/Project";
import ConversationThread from "../types/ConversationThread";
import useProjects from "../api/useProjects";
import ConversationThreadContainer from "../components/reusable/ConversationThreadContainer";
import {
  Container, DialogContent,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar, useMediaQuery, useTheme
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

// import {ChatProjectWorkspace} from "./ChatView";

const ConversationsManagerView = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const shouldUseDialog = useMediaQuery(theme.breakpoints.down("lg"))

  const [selectedProjectThread, setProjectThread] = useState<Project>()
  const [menuOpen, setMenuOpen] = useState(!shouldUseDialog)

  const params = useParams<{threadId: string}>()

  const {getProject} = useProjects()

  const handleClickDashboard = () => {
    navigate(`/activity/dashboard`)
    shouldUseDialog && setMenuOpen(false)
  }

  const handleClickThread = (thread:ConversationThread) => {
    if (thread.project_id) {
      getProject(thread.project_id).then(p => {
        setProjectThread(p.data)
        navigate(`/activity/threads/${thread._id}`)
        shouldUseDialog && setMenuOpen(false)
      })
    }
  }

  useEffect(() => {
    if (!params.threadId) {
      setProjectThread(undefined)
    }
  }, [params.threadId])

  const activitiesButton = (
    <>
      <List sx={{ flexShrink: 0 }}>
        <ListItemButton
          onClick={handleClickDashboard}
          selected={!Boolean(params.threadId)}
        >
          <ListItemIcon><AutoGraphRounded /></ListItemIcon>
          <ListItemText primary={"Activity Dashboard"} />
        </ListItemButton>
      </List>
      <Divider variant={"middle"} />
    </>
  )

  const threadList = <ConversationThreadList handleClickThread={handleClickThread} activeThreadId={params.threadId}/>

  const menu = (
    shouldUseDialog ? (
      <Dialog open={menuOpen} onClose={() => setMenuOpen(false)} fullScreen keepMounted>
        <DialogTitle sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <span>Activity</span>
          <div style={{ flexGrow: 1 }}/>
          <IconButton
            size={"large"}
            edge={"end"}
            color={"inherit"}
            onClick={() => setMenuOpen(false)}
            >
            <CloseRounded />
          </IconButton>
        </DialogTitle>
        {activitiesButton}
        <ConversationThreadList handleClickThread={handleClickThread} activeThreadId={params.threadId}/>
      </Dialog>
      ) : (
      <Drawer
        variant={"persistent"}
        open={menuOpen}
        anchor={"left"}
        sx={{
          width: 300,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {width: 300, boxSizing: "border-box", borderRight: "none", zIndex: (theme) => theme.zIndex.appBar - 1},
          display: "flex",
          flexDirection: "column",
          zIndex: (theme) => theme.zIndex.appBar - 1,
        }}
      >
        <Toolbar />
        {activitiesButton}
        <Divider variant={"middle"} />
        <Box sx={{ flexGrow: 1 }}>
          {threadList}
        </Box>
      </Drawer>
    )
  )

  return (<>

    <Container
      maxWidth={"md"}
      sx={{
        height: "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        pb: 2,
      }}
    >
      {(selectedProjectThread) ?
        (<>
          <SectionHeader>
            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
              <IconButton
                onClick={() => setMenuOpen(!menuOpen)}
                size={"large"}
                color={"inherit"}
                edge={"start"}
                sx={{ mr: 1 }}
              >
                <NotesRounded />
              </IconButton>
              <Typography variant="h6">
                {selectedProjectThread.name}
              </Typography>
            </div>
          </SectionHeader>
          <ConversationThreadContainer
            projectId={selectedProjectThread._id}
            projectName={selectedProjectThread.name}
            threadId={params.threadId}
            projectDescription={selectedProjectThread.description}
          />
        </>)
        :
        <ActivityDashboard menuOpen={menuOpen} setMenuOpen={(open) => setMenuOpen(open)}/>
      }
    </Container>
    {menu}
  </>)
}


export default ConversationsManagerView;
