import User from "../../../types/User";
import {StringUtils, UserUtils} from "../../../utils";
import {Avatar, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import React from "react";

export interface UserMessageProps {
  user: Partial<User>;
  message: string;
}

const UserMessage = ({ user, message }: UserMessageProps) => {

  const fullName = UserUtils.getFullName(user)
  const avatarProps = user?.first_name ? StringUtils.stringAvatar(`${fullName}`) : { children: "U" }

  return (
    <ListItem disableGutters alignItems={"flex-start"}>
      <ListItemAvatar><Avatar alt={`${fullName} picture`} src={user?.picture} {...avatarProps} /></ListItemAvatar>
      <ListItemText primary={fullName} secondary={message} />
    </ListItem>
  )
}

export default UserMessage;
