import {Dialog, DialogContent, DialogContentText, type DialogProps, DialogTitle, DialogActions, Button} from "@mui/material";
import useProjects from "../api/useProjects";
import {useEffect, useState} from "react";
import Project from "../types/Project";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import useConversationThread from "../api/useConversationThread";

interface DeleteThreadDialogProps extends DialogProps {
  projectId: string;
  threadId: string
}

const DeleteThreadDialog = ({projectId, threadId, onClose, children, open, ...rest}: DeleteThreadDialogProps) => {
  const navigate = useNavigate()
  const { deleteThread } = useConversationThread()
  const [deleting, setDeleting] = useState(false)

  const handleClose = () => {
    if (onClose) {
      // @ts-ignore
      onClose()
    }
  }

  const handleDelete = async () => {
    setDeleting(true)
    deleteThread(threadId)
      .then(() => {
        // @ts-ignore
        onClose && onClose()
        navigate(`/projects/${projectId}`)
      })
      .catch((err:any) => console.log(err))
      .finally(() => setDeleting(false))
  }

  const dialogContents = () => (
    <>
      <DialogTitle>{`Delete this thread?`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`This thread will be permanently removed from your organization.`}
          <br />
          <b>This cannot be undone.</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={deleting} variant={"text"} color={"error"} onClick={handleDelete}>Delete</LoadingButton>
      </DialogActions>
    </>
  )

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      {children || dialogContents()}
    </Dialog>
  )
}

export default DeleteThreadDialog
