import React, {useEffect, useState} from "react";
import {
  Autocomplete,
  Chip,
  CircularProgress, ListItem,
  TextField,
} from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';

import useUser from "../api/useUser";
import User, {FreeSoloUser} from "../types/User";
import UserInfoButton from "./UserInfoButton";


const filter = createFilterOptions<FreeSoloUser>();

interface UserSearchAutocompleteProps {
  handleChange:(users:FreeSoloUser[]) => void
  selectedUsers:FreeSoloUser[]
}

const UserSearchAutocomplete = ({handleChange,selectedUsers}:UserSearchAutocompleteProps) => {
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<readonly FreeSoloUser[]>([]);

  const api = useUser()

  React.useEffect(() => {
    let active = true;
    if (!open) {
      return undefined;
    }
    (async () => {
      setLoading(true)
      api.list(query).then((r) => {
        if (active) {
          setOptions(r.data)
          setLoading(false)
        }
      })
    })();

    return () => {
      active = false;
    };
  }, [open, query]);

  return (
    <Autocomplete
      id="user-search"
      multiple
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option._id ? option._id === value._id : option.email === value.email}
      getOptionLabel={(option) => typeof(option) === "string" ? option : option.label || option.email }
      value={selectedUsers}
      onChange={(event, value: any, reason) => {
        handleChange(value)
      }}
      options={options}
      loading={loading}
      autoHighlight
      freeSolo
      sx={{ minWidth: 300 }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.email);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            _id: "",
            user_id: "",
            email: inputValue,
            label: `Invite "${inputValue}"`,
          });
        }

        return filtered;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onKeyUp={(event:any) => {
            // ignore if keyup is enter
            if (event.keyCode === 13) {
              return
            }
            setQuery(event.target.value as string)
          }}
          label="Invite Team Members"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderTags={(value: readonly User[], getTagProps) =>
        value.map((option: User, index: number) => (
          <Chip variant="outlined" label={option.email} {...getTagProps({ index })} />
        ))
      }
      renderOption={(props, option: FreeSoloUser) => {
        if (option.label) {
          return <ListItem {...props}>{option.label}</ListItem>;
        } else {
          return (
            <UserInfoButton user={option} {...props} />
          )
        }
      }}
    />
  );
}


export default UserSearchAutocomplete;
