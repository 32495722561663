import ConversationThread, {ConversationThreadStatus} from "../types/ConversationThread";
import ConversationThreadMessage from "../types/ConversationThreadMessage";
import axios from "axios";

const useConversationThread = () => {
  const listThreads = (projectId?: string | undefined): Promise<{ data: ConversationThread[] }> => axios.get(
    "/api/conversations/threads",
    { params: { project_id: projectId } }
  )

  const getThread = (threadId: string): Promise<{ data: ConversationThread }> => axios.get(
    `/api/conversations/threads/${threadId}`,
    { params: { } }
  )

  const listThreadMessages = (threadId: string): Promise<{ data: ConversationThreadMessage[] }> => axios.get(
    `/api/conversations/threads/${threadId}/messages`,
    { params: { } }
  )

  const postThreadStatus = (threadId: string, status: ConversationThreadStatus): Promise<{ data: ConversationThreadMessage }> => axios.post(
    `/api/conversations/threads/${threadId}/status`,
    { status }
  )

  const deleteThread = (threadId: string): Promise<{ data: ConversationThread }> => axios.delete(
    `/api/conversations/threads/${threadId}`,
    { params: { } }
  )

  const getAnalytics = (projectId?: string): Promise<{ data: {history: any[], history_by_project: any[]} }> => axios.get(
    `/api/conversations/analytics`,
    { params: { project_id: projectId } }
  )

  return {
    listThreads,
    getThread,
    listThreadMessages,
    postThreadStatus,
    deleteThread,
    getAnalytics,
  }
}

export default useConversationThread
