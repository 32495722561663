import DocumentList from "../../components/DocumentList"
import {Box, Button, DialogContent, Drawer, IconButton, Toolbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import React from "react";

interface DocumentsContainerProps {
  projectId: string
  open: boolean
  onClose: () => void
}

const DocumentsContainer = (props: DocumentsContainerProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))

  const documentList = <DocumentList projectId={props.projectId} disableActions />

  const title = "Documents"

  return (
    !isMobile ? (
      <Drawer
        variant={"persistent"}
        open={props.open}
        anchor={"right"}
        sx={{
          width: 300,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {width: 300, boxSizing: "border-box", borderLeft: "none"},
          display: "flex",
          flexDirection: "column",
          zIndex: (theme) => theme.zIndex.appBar - 1,
        }}
      >
        <Toolbar />
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexShrink: 0,
          }}
        >
          <Typography
            variant={"h6"}>{title}</Typography>
          <IconButton
            size={"large"}
            edge={"end"}
            color={"inherit"}
            onClick={props.onClose}
          >
            <CloseRounded/>
          </IconButton>
        </Toolbar>
        <Box sx={{flexGrow: 1, overflow: "auto"}}>
          {documentList}
        </Box>
      </Drawer>
    ) : (
      <Dialog open={props.open} fullScreen keepMounted>
        <DialogTitle sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <span>{title}</span>
          <div style={{ flexGrow: 1 }}/>
          <IconButton
            size={"large"}
            edge={"end"}
            color={"inherit"}
            onClick={() => props.onClose()}
          >
            <CloseRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pl: { xs: 0 }, pr: { xs: 0 } }}>
        {documentList}
        </DialogContent>
      </Dialog>
    )
  )
}

export default DocumentsContainer
