import ProjectDocument from "../types/ProjectDocument";
import axios from "axios";

const useDocuments = () => {
  const listDocuments = (projectId: string): Promise<{ data: ProjectDocument[] }> => axios.get(
    "/api/documents",
    { params: { project_id: projectId } }
  )

  const uploadDocument = (projectId: string, file: File): Promise<{ data: ProjectDocument }> => {
    const formData = new FormData()
    formData.append("document_file", file)
    return axios.post("/api/documents", formData, {
      params: { project_id: projectId },
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  const viewDocument = (documentId: string): Promise<{ data: Blob }> => axios.get(
    `/api/documents/${documentId}/view`,
    { responseType: "blob" }
  )

  const deleteDocument = (documentId: string): Promise<{ data: ProjectDocument }> => axios.delete(`/api/documents/${documentId}`)


  return {
    listDocuments,
    uploadDocument,
    viewDocument,
    deleteDocument,
  }
}

export default useDocuments
