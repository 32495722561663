import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Link,
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  DialogContentText
} from "@mui/material";
import {Step as GuidedStep} from "./DialogManager";
import {LoadingButton} from "@mui/lab";
import LLMSelect from "./projectSettings/LLMSelect";
import useProjects from "../api/useProjects";
import { OnboardingTooltip } from "./StyledTooltip";
import Project from "../types/Project";
import DocumentList from "./DocumentList";
import ProjectPermissions from "./projectSettings/ProjectPermissions";
import {useReward} from "react-rewards";


interface GuidedProjectCreationProps {
  open: boolean
  onClose?: () => void
  isCancellable?: boolean
  projectId?: string
  defaultFields?: Partial<Project>
}

const GuidedProjectSettingsDialog = (props: GuidedProjectCreationProps) => {
  const {defaultFields} = props
  const { projects, getProject, createProject, updateProject, generateProjectDescription } = useProjects()

  const { reward: confettiReward, isAnimating: isConfettiAnimating } = useReward('confetti-reward', 'confetti')
  const navigate = useNavigate()

  const [currentStep, setCurrentStep] = useState(0)

  const [project, setProject] = useState<Project>()

  const [name, setName] = useState("")
  const [nameError, setNameError] = useState(false)

  const [llm, setLLM] = useState("gpt-4")
  const [description, setDescription] = useState("")
  const [agentInstructions, setAgentInstructions] = useState("")

  const [loading, setLoading] = useState(false)
  const [generatingDescription, setGeneratingDescription] = useState(false)

  useEffect(() => {
    if (defaultFields?.name) setName(defaultFields.name)
    if (defaultFields?.description) setDescription(defaultFields.description)
    if (defaultFields?.agent_instructions) setAgentInstructions(defaultFields.agent_instructions)
  }, [defaultFields])

  const handleCreateProject = () => {
    setLoading(true)
    createProject({ name, description })
      .then((response) => {
        setProject(response.data)
        if (!description) handleGenerateDescription()
        if (projects && projects.length === 0) confettiReward()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const handleUpdateProject = () => {
    setLoading(true)
    updateProject({ ...project, name, description, agent_instructions: agentInstructions })
      .then((response) => {
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const handleGenerateDescription = () => {
    setGeneratingDescription(true)
    setDescription("Generating...")
    generateProjectDescription(name)
      .then((res) => setDescription(res.data.output))
      .catch((err) => {
        console.log(err)
        setDescription("")
      })
      .finally(() => setGeneratingDescription(false))

  }

  const handleClose = () => {
    props.onClose && props.onClose()

    const cleanup = () => {
      setCurrentStep(0)
      setName("")
      setNameError(false)
      setDescription("")
      setAgentInstructions("")
    }

    setTimeout(() => cleanup(), 300)
  }

  useEffect(() => {
    if (props.projectId) {
      getProject(props.projectId)
        .then((response) => {
          setProject(response.data)
          setName(response.data.name)
          setDescription(response.data.description || "")
        })
        .catch((err) => console.log(err))
    }
  }, [props.projectId]);

  const nameAndDescription = () => (
    <Box>
      <DialogTitle>New Project</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ pt: 1 }}>
          <TextField
            id={"project-name-textfield"}
            label={"Project Name"}
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={nameError}
            helperText={nameError ? "Project name is required" : undefined}
          />
          <OnboardingTooltip
            title={"A description will help people understand the purpose of the project."}
            placement={"right-start"}
            open={currentStep === 1 && description.trim() === ""}
          >
            <TextField
              sx={{ mt: 1 }}
              InputLabelProps={{ shrink: true }}
              label={"Project Description"}
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              disabled={generatingDescription}
              placeholder={
                "This is a project is a virtual assistant " +
                "for teachers / coaches / principals " +
                "to find documents relating to ... and get support creating ... "
              }
              helperText={
                !defaultFields?.agent_instructions && (
                  <Link onClick={handleGenerateDescription} sx={{ cursor: "pointer" }}>
                    Auto generate description ✨
                  </Link>
                )
              }
            />
          </OnboardingTooltip>
          {defaultFields?.agent_instructions &&
            <TextField
              sx={{ mt: 1 }}
              InputLabelProps={{ shrink: true }}
              label={"AI Agent Instructions"}
              fullWidth
              multiline
              rows={10}
              value={agentInstructions}
              onChange={(e) => setAgentInstructions(e.target.value)}
              placeholder={
                "You are an AI assistant whose goal is to help answer questions and complete tasks."
              }
            />
          }
        </Stack>
      </DialogContent>
      <DialogActions>
        {props.isCancellable && props.onClose && (
          <Button variant={"text"} onClick={handleClose}>Cancel</Button>
        )}
        <LoadingButton loading={loading} disabled={name.trim() === ""} onClick={() => {
          if (name.trim() === "") {
            setNameError(true)
            return
          }
          props.projectId ? handleUpdateProject() : handleCreateProject()
          setCurrentStep(1)
        }}>
          Create Project
        </LoadingButton>
      </DialogActions>
    </Box>
  )

  const addDocumentsStep = () => (
    <Box>
      <DialogTitle id={"confetti-reward"}>Add documents</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ pt: 1 }}>
          <DialogContentText>
            Add knowledge to the project by uploading documents (PDFs).
            Adding documents will make them searchable and allow the AI to answer questions about them.
          </DialogContentText>
        </Stack>
      </DialogContent>
      {project?._id &&
        <DocumentList projectId={project._id} />
      }
      <DialogActions>
        <Button variant={"text"} onClick={() => setCurrentStep(2)}>Skip</Button>
        <LoadingButton loading={loading} onClick={() => setCurrentStep(2)}>
          Next
        </LoadingButton>
      </DialogActions>
    </Box>
  )

  const permissionsStep = () => (
    <Box>
      <DialogTitle id={"confetti-reward"}>Invite others</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ pt: 1 }}>
          <DialogContentText>
            Add content contributors to the project or invite others to chat with the AI assistant.
          </DialogContentText>
          {project?._id &&
            <ProjectPermissions projectId={project?._id} hideCurrentUser/>
          }
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} onClick={() => setCurrentStep(1)}>Back</Button>
        <span style={{flexGrow: 1}}/>
        <Button variant={"text"} onClick={() => setCurrentStep(3)}>Skip</Button>
        <LoadingButton loading={loading} onClick={() => setCurrentStep(3)}>
          Next
        </LoadingButton>
      </DialogActions>
    </Box>
  )

  const customizeStep = () => (
    <Box>
      <DialogTitle id={"confetti-reward"}>Customize AI Assistant</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ pt: 1 }}>
          <DialogContentText>
            Choose which LLM service to use for this AI assistant and create custom prompt instructions for the assistant.
          </DialogContentText>
          <LLMSelect value={llm} onChange={(v) => setLLM(v)}/>
          <TextField
            sx={{ mt: 1 }}
            InputLabelProps={{ shrink: true }}
            label={"AI Agent Instructions"}
            fullWidth
            multiline
            rows={8}
            value={agentInstructions}
            onChange={(e) => setAgentInstructions(e.target.value)}
            placeholder={
              "You are an AI assistant whose goal is to help answer questions and complete tasks."
            }
            helperText={
              "Skip this step and use the default instructions if you're not sure what to write."
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} onClick={() => setCurrentStep(2)}>Back</Button>
        <span style={{flexGrow: 1}}/>
        <LoadingButton loading={loading} onClick={() => {
          handleUpdateProject()
          handleClose()
          navigate(`/projects/${project?._id}`)
        }}>
          Let's go!
        </LoadingButton>
      </DialogActions>
    </Box>
  )

  return (
    <Dialog open={props.open} maxWidth={"sm"} fullWidth onClose={() => props.isCancellable && props.onClose && handleClose()}>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={currentStep} sx={{p: 2}}>
            <Step key={0}>
              <StepLabel>{"Create"}</StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel>{"Add documents"}</StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel>{"Invite Others"}</StepLabel>
            </Step>
            <Step key={3}>
              <StepLabel>{"Customize AI"}</StepLabel>
            </Step>
        </Stepper>
        {currentStep === 0 && nameAndDescription()}
        {currentStep === 1 && addDocumentsStep()}
        {currentStep === 2 && permissionsStep()}
        {currentStep === 3 && customizeStep()}
      </Box>
    </Dialog>
  )
}

export default GuidedProjectSettingsDialog
