import React, {useEffect, useState} from "react";

import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';

import ConversationThread, {ConversationThreadStatus} from "../../types/ConversationThread";

interface ConversationThreadListItemProps {
  handleClick: (thread:ConversationThread) => void,
  activeThreadId?:string;
  thread:ConversationThread;
}

const ConversationThreadListItem = ({handleClick,activeThreadId,thread}:ConversationThreadListItemProps) => {
  const secondaryActionLookup = {
    [ConversationThreadStatus.success]: <ThumbUpIcon fontSize="small" color="success"/>,
    [ConversationThreadStatus.help]: <ThumbDownIcon fontSize="small" color="warning"/>,
  }
  return (
      <ListItem
        secondaryAction={thread.status ? secondaryActionLookup[thread.status] : <></>}
        disablePadding
      >
        <ListItemButton
          alignItems="flex-start"
          onClick={() => handleClick(thread)}
          selected={activeThreadId === thread._id}
        >
        <ListItemAvatar>
          <Avatar alt={thread.user.last_name} src={thread.user.picture} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              component="span"
              variant="body1"
              color="text.primary"
              style={{
                display: 'block',
                // maxWidth: 300,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {thread.initial_query}
            </Typography>
          }
          secondary={
            <Typography
              component="span"
              variant="body2"
              color="text.primary"
              style={{
                display: 'block',
                // maxWidth: 300,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {thread.latest_response}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
    
  )
}

export default ConversationThreadListItem
