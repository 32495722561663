import React, {useEffect, useState} from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  PaperProps, ListItemButton, Tooltip, Menu, MenuItem, Paper
} from "@mui/material";
import ProjectDocument from "../types/ProjectDocument";
import useDocuments from "../api/useDocuments";
import {DateUtils} from "../utils";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnack } from "../contexts/useSnackContext";
import {IconDots, IconFileTypePdf, IconFileUpload} from "@tabler/icons-react";


type FileUploadStep = "uploading" | "analyzing" | "indexing"

interface DocumentListProps extends PaperProps {
  projectId?: string
  disableUpload?: boolean
  disableDelete?: boolean
  disableActions?: boolean
}

const DocumentList = (props: DocumentListProps ) => {
  const snackbar = useSnack()

  const { projectId, disableUpload, disableDelete, disableActions, ...paperProps } = props
  const { listDocuments, uploadDocument, deleteDocument } = useDocuments()

  const [documents, setDocuments] = useState<ProjectDocument[]>([])
  const [deletingDocId, setDeletingDocId] = useState<string>()
  const [isDraggingFiles, setIsDraggingFiles] = useState(false)
  const [uploadName, setUploadName] = useState("")
  const [uploadCurrentStep, setUploadCurrentStep] = useState<FileUploadStep>()
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>("")

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDraggingFiles(true)
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => setIsDraggingFiles(false)

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDraggingFiles(false)
    const pdfs = Array.from(e.dataTransfer.files).filter((file) => file.type === "application/pdf")
    if (pdfs.length > 0) handleFileUpload(pdfs[0])
  }

  const handleSelectedDocuments = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    handleFileUpload(e.target.files[0])
  }

  const handleFileUpload = (file: File) => {
    if (!projectId) {
      snackbar.addErrorMessage("No project selected.")
      return
    }
    setUploadName(file.name)
    setTimeout(() => setUploadCurrentStep("uploading"), 1000)
    setTimeout(() => setUploadCurrentStep("analyzing"), 3000)
    setTimeout(() => setUploadCurrentStep("indexing"), 4000)
    uploadDocument(projectId, file)
      .then((response) => {
        setDocuments([...documents, response.data])
        setUploadCurrentStep(undefined)
        setUploadName("")
        snackbar.addSuccessMessage("Uploaded document!")
      })
      .catch((err) => {
        snackbar.addErrorMessage("Failed to upload document.")
      })
  }

  const handleDocumentDelete = (documentId: string) => {
    setDeletingDocId(documentId)
    deleteDocument(documentId)
      .then((response) => {
        setDocuments(documents.filter((doc) => doc._id !== documentId))
        setDeletingDocId(undefined)
        snackbar.addInfoMessage("Deleted document.")
      })
      .catch((err) => {
        console.log(err)
        snackbar.addErrorMessage("Failed to delete document.")
      })

  }

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, documentId: string) => {
    setSelectedDocumentId(documentId)
    setMenuAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => setMenuAnchorEl(null)

  useEffect(() => {
    if (projectId) {
      listDocuments(projectId)
        .then((response) => {
          setDocuments(response.data)
        })
        .catch((err) => {
          console.log(err)
          snackbar.addErrorMessage("Failed to get documents.")
        })
    }
  }, [projectId])

  let defaultPaperProps: PaperProps = {
    id: "file-list",
    elevation: 0,
    sx: { height: "100%" },
    ...paperProps
  }

  if (!disableUpload) {
    defaultPaperProps.elevation = isDraggingFiles ? 3 : 0
    defaultPaperProps.onDrop = handleDrop
    defaultPaperProps.onDragOver = handleDragOver
    defaultPaperProps.onDragLeave = handleDragLeave
  }

  const UploadButton = () => (
    <ListItemButton component={"label"}>
      <input type={"file"} hidden onChange={handleSelectedDocuments} accept="application/pdf" />
      <ListItemIcon>
        <IconFileUpload />
      </ListItemIcon>
      <ListItemText
        primary={"Click to upload"}
        secondary={"or drop in a PDF."}
        primaryTypographyProps={{
          fontWeight: "bold",
          color: "primary"
        }}
      />
    </ListItemButton>
  )

  const documentListItem = ({ key, document }: { key: number, document: ProjectDocument }) => {

    const contents = (
      <>
        <ListItemIcon>
          <IconFileTypePdf />
        </ListItemIcon>
        <ListItemText
          primary={document.name}
          secondary={`${document.updated_at === document.created_at ? "Created" : "Updated"} on ${DateUtils.getDateString(new Date(document.updated_at), true)}`}
          primaryTypographyProps={{
            noWrap: true,
          }}
        />
      </>
    )

    return (
      disableActions ? (
        <ListItem key={key}>
          {contents}
        </ListItem>
      ) : (
        <ListItemButton key={key} onClick={(e) => handleOpenMenu(e, document._id)}>
          {contents}
          <IconDots />
        </ListItemButton>
      )
    )
  }

  const stepMap: { [step: string]: { label: string, progress: number }} = {
    "uploading": { label: "Uploading document...", progress: 25 },
    "analyzing": { label: "Analyzing document...", progress: 66 },
    "indexing": { label: "Indexing document...", progress: 85 },
  }

  const InProgressListItem = ({ primary, secondary, progress }: { primary: string, secondary: string, progress?: number }) => (
    <Tooltip title={"Cannot edit."} arrow placement={"left"}>
      <ListItem>
        <ListItemIcon sx={{ mx: 0.5 }}>
          <CircularProgress size={30} color={"secondary"} variant={!!progress ? "determinate" : "indeterminate"} value={progress} />
        </ListItemIcon>
        <ListItemText
          primary={primary}
          secondary={secondary}
          primaryTypographyProps={{
            noWrap: true,
          }}
        />
      </ListItem>
    </Tooltip>
  )

  const DocumentActionsMenu = () => (
    <Menu
      open={!!menuAnchorEl}
      anchorEl={menuAnchorEl}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Tooltip title={disableDelete && "Only owners or editors can delete documents."} arrow>
        <div>
          <MenuItem
            disabled={disableDelete}
            onClick={() => {
              setMenuAnchorEl(null)
              handleDocumentDelete(selectedDocumentId)
            }}
          >
            Delete
          </MenuItem>
        </div>
      </Tooltip>
    </Menu>
  )

  return (<>
    <Paper {...defaultPaperProps}>
      <List sx={{ height: "100%" }}>
        {(!disableUpload && !disableActions) && <UploadButton/>}
        {documents.map((doc, i) => (
          doc._id === deletingDocId ? (
            <InProgressListItem key={i} primary={doc.name} secondary={"Deleting document..."} />
          ) : (
            documentListItem({ key: i, document: doc })
          ))
        )}
        {uploadCurrentStep && (
          <InProgressListItem
            primary={uploadName}
            secondary={stepMap[uploadCurrentStep].label}
            progress={stepMap[uploadCurrentStep].progress}
          />
        )}
      </List>
    </Paper>
    <DocumentActionsMenu />
  </>)
}

export default DocumentList
