import React from "react";
import {Button, Stack, styled, Tooltip, TooltipProps, Typography} from "@mui/material";


const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${"MuiTooltip-tooltip"}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  [`& .${"MuiTooltip-arrow"}`]: {
    color: theme.palette.secondary.main,
  }
}))

interface OnboardingTooltipProps extends TooltipProps {
  body?: string
  actionLabel?: string
  onAction?: () => void
}

export const OnboardingTooltip = (props: OnboardingTooltipProps) => {
  const { title, body, actionLabel, onAction, ...rest } = props;

  return (
    <StyledTooltip {...rest} title={
      <Stack spacing={2} alignItems={"flex-start"} sx={{ p: 1 }}>
        <div>
          <Typography>{props.title}</Typography>
          {props.body && <Typography variant={"body2"}>{props.body}</Typography>}
        </div>
        {onAction && <Button
          variant={"outlined"}
          color={"inherit"}
          size={"small"}
          onClick={onAction}
        >{actionLabel || "Next"}</Button>}
      </Stack>
    } />
  )
}

export default StyledTooltip
