import {Avatar, CardMedia, useTheme} from "@mui/material";
import React from "react";
import {BlurOn} from "@mui/icons-material";


const useAnimationProps = (color?: string) => {
  const theme = useTheme()

  const background = color ? theme.palette.augmentColor({
    color: {
      main: color
    },
    name: "background",
  }) : theme.palette.primary

  return ({
    background: `linear-gradient(90deg, ${background.light}, ${background.dark})`,
    backgroundSize: "200% 100%",
    animation: "smoothWavyGradient 5s infinite",
    "@keyframes smoothWavyGradient": {
      "0%": {
        backgroundPosition: "0 0",
      },
      "50%": {
        backgroundPosition: "100% 0",
      },
      "100%": {
        backgroundPosition: "0 0",
      },
    },
  })
}

interface ProjectImageProps {
  src?: string
  image?: string
  imgBackgroundColor?: string
}

const ProjectCardImage = (props: ProjectImageProps) => {
  const animationProps = useAnimationProps(props.imgBackgroundColor)

  return (
    <CardMedia
      sx={{
        height: 140,
        ...((props.src || props.image) ? {} : animationProps)
      }}
      src={props.src}
      image={props.image}
      component={"div"}
    />
  )
}

const ProjectAvatar = (props: ProjectImageProps) => {
  const animationProps = useAnimationProps(props.imgBackgroundColor)

  return <Avatar
    variant={"rounded"}
    sx={{
      width: 24,
      height: 24,
      ...((props.src || props.image) ? {} : animationProps)
    }}
  ><BlurOn /></Avatar>
}

export { useAnimationProps, ProjectCardImage, ProjectAvatar }
