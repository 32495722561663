import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText, Paper,
  Tooltip, Link, type PaperProps, type ListItemProps
} from "@mui/material";
import React, {ReactNode} from "react";
import useUserContext from "../contexts/useUserContext";
import {AccountCircle, Logout, Person} from "@mui/icons-material";
import User from "../types/User";


interface UserInfoButtonProps extends ListItemProps {
  user?: User
  invitedEmail?: string
  secondaryAction?: ReactNode
}

const UserInfoButton = ({ user, invitedEmail, secondaryAction, ...listItemProps }: UserInfoButtonProps) => {

  const name = user?.first_name ? (
    user.last_name ? `${user.first_name} ${user.last_name}`
      : `${user.first_name}`
  ) : invitedEmail


  return (
      <ListItem
        sx={{p: 0}}
        secondaryAction={secondaryAction}
        {...listItemProps}
      >
        <ListItemAvatar>
          <Avatar src={user?.picture}><Person /></Avatar></ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={user?.email || "( Invited )"}
          primaryTypographyProps={{
            noWrap: true,
          }}
          secondaryTypographyProps={{
            noWrap: true,
          }}
        />
      </ListItem>
  )
}

export default UserInfoButton
