import React, {useEffect, useState} from "react";
import {
  Badge,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Menu,
} from "@mui/material";
import {
  AddModeratorOutlined as AddModeratorIcon,
  BookmarkAddOutlined as BookmarkAddIcon,
} from "@mui/icons-material";
import useProjects from "./api/useProjects";
import Project from "./types/Project";


const InvitedProjects = ({invitedProjects, onClick }:{invitedProjects:Project[], onClick: () => void}) => {
  const { acceptProjectInvitation } = useProjects()
  const handleAccept = async (projectId:string, permissionId?:string) => {
    if (permissionId) {
      acceptProjectInvitation(projectId, permissionId).then((response) => {
        console.log('accepted', response)
        onClick()
      })
    }
  }

  return (
    <List>
      {invitedProjects.map((project, i) => (
        <ListItem key={i} dense secondaryAction={<Button variant={"text"} onClick={() => handleAccept(project._id, project?.current_user_permission?._id)}> Accept </Button>}>
          <ListItemText primary={project.name} secondary={project.current_user_permission?.invited_by_email} />
        </ListItem>
      ))}
    </List>
  )
}


export const InvitedProjectsIndicator = () => {
  const [open, setOpen] = useState(false)
  const { invitedProjects } = useProjects()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (invitedProjects && invitedProjects.length === 0) {
      setOpen(false)
    }
    // TODO: decide if showing this right away is useful or wait for first project creation
    // else if (invitedProjects && invitedProjects.length > 0) {
    //   setOpen(true)
    // }
  }, [invitedProjects])

  const notificationsMenu = () => (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      slotProps={
        {
          paper: {
            sx: {
              width: 400,
            }
          }
        }
      }
    >
      <ListItem dense>You've been invited!</ListItem>
      {invitedProjects && <InvitedProjects invitedProjects={invitedProjects} onClick={() => setAnchorEl(null)} />}
    </Menu>
  )

  return (
    <>
      {(invitedProjects && invitedProjects.length > 0) &&
        <Tooltip title={"Project Invitations"} arrow>
          <div>
            <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
              <Badge badgeContent={invitedProjects.length} color="warning">
                <BookmarkAddIcon/>
              </Badge>
            </IconButton>
          </div>
        </Tooltip>
      }
      {notificationsMenu()}
    </>
  )
}

export default InvitedProjects
