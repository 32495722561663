import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

export interface SnackMessage {
  id: number
  text: string
  severity: "success" | "info" | "warning" | "error"
  requiresAction?: boolean
}

interface SnackContextProps {
  messages: SnackMessage[],
  addMessage: (message: SnackMessage) => void,
  clearMessage: (messageId: number) => void,
}

const SnackContext = createContext<SnackContextProps>({
  messages: [],
  addMessage: () => {},
  clearMessage: () => {},
});

const useSnackContext = () => {
  const context = useContext(SnackContext);
  if (!context) {
    throw new Error('useSnackContext must be used within a SnackProvider');
  }
  return context;
}


export const SnackProvider = ({children}: {children: ReactNode}) => {
  const [messages, setMessages] = useState<SnackMessage[]>([]);

  const addMessage = (message: SnackMessage, requiresAction:boolean=false) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  }

  const clearMessage = (messageId: number) => {
    setMessages((prevMessages) => prevMessages.filter((message) => message.id !== messageId));
  }

  useEffect(() => {
    if (messages.length > 0) {
      const timer = setTimeout(() => {
        setMessages(messages.slice(1));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [messages]);

  return (
    <SnackContext.Provider value={{messages, addMessage, clearMessage}}>
      {children}
    </SnackContext.Provider>
  )
}

export const useSnack = () => {
  const {messages, addMessage} = useSnackContext();

  const addSuccessMessage = (text: string, requiresAction:boolean=false) => {
    addMessage({id: Math.random(), text, requiresAction, severity: "success"});
  }

  const addInfoMessage = (text: string, requiresAction:boolean=false) => {
    addMessage({id: Math.random(), text, requiresAction, severity: "info"});
  }

  const addWarningMessage = (text: string, requiresAction:boolean=true) => {
    addMessage({id: Math.random(), text, requiresAction, severity: "warning"});
  }

  const addErrorMessage = (text: string, requiresAction:boolean=true) => {
    addMessage({id: Math.random(), text, requiresAction, severity: "error"});
  }

  return {messages, addSuccessMessage, addInfoMessage, addWarningMessage, addErrorMessage};
}

export const SnackbarList = () => {
  const {messages,clearMessage} = useSnackContext();
  const handleClose = (messageId: number) => {
    clearMessage(messageId);
  }

  return (
    <>
      {messages.map((message, index) => (
        <Snackbar
          autoHideDuration={message.requiresAction ? undefined : 4000}
          key={message.id}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={true}
          onClose={() => {
            // NOTE: Closing currently remove every message.
            handleClose(message.id);
          }}
          style={{ top: `${index * 70 + 70}px` }} // Dynamic top positioning
        >
          <Alert
            onClose={() => handleClose(message.id)}
            severity={message.severity}
            sx={{ width: '100%' }}
          >
            {message.text}
          </Alert>
        </Snackbar>
      ))}
    </>
  )
}


export default useSnackContext
