import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import User from "../types/User";
import useUser from "../api/useUser";

type AuthenticationStatus = "authenticated" | "unauthenticated" | "loading";

interface UserContextProps {
  user?: User
  authenticationStatus: AuthenticationStatus
}

const UserContext = createContext<UserContextProps>({
  user: undefined,
  authenticationStatus: "loading"
});

export const UserProvider = ({children}: { children: ReactNode }) => {
  const userAPI = useUser()

  const [authenticationStatus, setAuthenticationStatus] = useState<AuthenticationStatus>("loading")

  const [user, setUser] = useState<User>()

  useEffect(() => {
    if (authenticationStatus === "loading") {
      userAPI.me()
        .then((response) => {
          console.log(response)
          setUser(response.data)
          setAuthenticationStatus("authenticated")
        })
        .catch((err) => {
          setAuthenticationStatus("unauthenticated")
        })
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, authenticationStatus }}>
      {children}
    </UserContext.Provider>
  )
}

const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}

export default useUserContext
