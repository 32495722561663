import React, {useState, useEffect} from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
  CardHeader, IconButton,
} from '@mui/material';
import { groupBy, sumBy } from 'lodash';
import { StringUtils } from "../utils";
import SectionHeader from '../components/SectionHeader';
import useConversation from "../api/useConversationThread";
import {NotesRounded} from "@mui/icons-material";

const ActivityDashboard = ({ menuOpen, setMenuOpen }: { menuOpen: boolean, setMenuOpen: (open: boolean) => void }) => {
  const api = useConversation();
  const [messageHistory, setMessageHistory] = useState<number[]>([]);
  const [userHistory, setUserHistory] = useState<number[]>([]);
  const [projectMessageHistory, setProjectMessageHistory] = useState<any[]>([]);

  useEffect(() => {
    api.getAnalytics().then((res) => {
      setMessageHistory(res.data.history.map((h: any) => h.thread_count))
      setUserHistory(res.data.history.map((h: any) => h.unique_user_count))
      setProjectMessageHistory(res.data.history_by_project)
    })
  }, []);

  return (
    <Box>
      <SectionHeader>
        <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
          <IconButton
            onClick={() => setMenuOpen(!menuOpen)}
            size={"large"}
            color={"inherit"}
            edge={"start"}
            sx={{ mr: 1 }}
          >
            <NotesRounded />
          </IconButton>
          <Typography variant="h6">
            Dashboard
          </Typography>
        </div>
      </SectionHeader>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SparklineCard data={messageHistory} title="Messages this week" />
        </Grid>
        <Grid item xs={12} md={6}>
          <SparklineCard data={userHistory} title="Users this week" />
        </Grid>
        <Grid item xs={12}>
          <Box mt={4}>
            <Card variant="outlined">
              <CardHeader title="Weekly Messages" />
              <CardContent>
                <StackedVerticalBarChart
                  data={projectMessageHistory}
                />
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const SparklineCard = ({ data, title }: { data: number[] | undefined, title: string }) => {
  if (!data) {
    return <Skeleton style={{ height: "100%" }} width={100} />;
  }

  const getPath = (data: number[]) => {
    let maxValue = Math.max(...data);
    let minValue = Math.min(...data);
    let path = '';
    let prevX: number = 0;
    let prevY: number = 0;

    data.forEach((point, i) => {
      const x = (i / (data.length - 1)) * 100;
      const y = 50 - ((point - minValue) / (maxValue - minValue)) * 50;

      if (i === 0) {
        path += `M ${x} ${y} `;
      } else {
        const midX = (prevX + x) / 2;
        const midY = (prevY + y) / 2;

        path += `Q ${prevX} ${prevY}, ${midX} ${midY} `;
      }

      prevX = x;
      prevY = y;
    });

    path += `T ${prevX} ${prevY}`;
    return path;
  };

  const calcPercentChange = (history: number[]) => {
    const percentChange = ((history[history.length - 1] - history[0]) / history[0]) * 100;
    return `${percentChange > 0 ? '+' : ''}${percentChange.toFixed(2)}%`
  }

  return (
    <Card variant={"outlined"}>
      <CardHeader title={title} />
      <CardContent style={{ minHeight: 'calc(30vh - 60px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Box display="flex" alignItems="center">
          <Typography variant="h4" style={{ flexGrow: 1 }}>
            {data[0]}
          </Typography>
          {data && data.length > 1 &&
            <svg width="100" height="50">
              <path
                d={getPath(data)}
                fill="none"
                stroke="#007BFF"
                strokeWidth="2"
              />
            </svg>
          }
        </Box>
        <Typography variant="body1">{calcPercentChange(data)}</Typography>
      </CardContent>
    </Card>
  );
}

function getMondayOfISOWeek(year: number, week: number): Date {
  const simple = new Date(year, 0, 1 + (week - 1) * 7);
  const day = simple.getDay();
  const dayOffset = day === 0 ? 6 : day - 1; // adjust for Sunday = 0 in JavaScript, ISO weeks start on Monday
  const isoWeekStart = new Date(simple.valueOf() - dayOffset * 24 * 60 * 60 * 1000);
  return isoWeekStart;
}

interface DataRecord {
  thread_count: number
  year: number
  week: number
  unique_user_count: number
  project_id: string
  project_name: string
}

const VerticalBarChart = ({ data }: { data: DataRecord[] | undefined }) => {
  if (!data) {
    return <Skeleton style={{ height: "100%" }} width={100} />;
  }
  const maxValue = Math.max(...data.map((item) => item.unique_user_count));

  return (
    <Box sx={{ m: 1, display: "flex", flexDirection: "row", alignItems: 'flex-end', borderBottom: "1px solid #007BFF" }}>
      {data.map((item, index) => (
        <Box key={index} display="flex" flexDirection="column-reverse" alignItems="center" ml={1} mr={1} style={{height: 250}}>
          <Box
            sx={{
              backgroundColor: "007BFF",
              width: "24px",
              height: `${(item.unique_user_count / maxValue) * 100}%`,
              borderRadius: "5px 5px 0 0",
              minHeight: '4px' // Ensuring a minimum height so that the bar is visible
            }}
          ></Box>
          <Typography variant="body2" sx={{ marginTop: "8px" }}>
            {item.unique_user_count}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "center", width: "100px" }}>
            {item.week}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};


const StackedVerticalBarChart = ({ data }: { data: DataRecord[] | undefined }) => {
  if (!data) {
    return <div>Loading...</div>; // Placeholder
  }

  const weekGroups = groupBy(data, item => `${item.year}-${item.week}`);
  let maxStackedHeight = 0;
  for (const weekData of Object.values(weekGroups)) {
    const weeklyStackedHeight = sumBy(weekData, 'thread_count');
    maxStackedHeight = Math.max(maxStackedHeight, weeklyStackedHeight);
  }

  return (
    <Box sx={{ m: 1, display: "flex", flexDirection: "row", alignItems: 'flex-end', borderBottom: "1px solid #007BFF" }}>
      {Object.entries(weekGroups).map(([week, weekData], index) => {
        // Group by project within each week
        const projectGroups = groupBy(weekData, 'project_id');
        let stackedHeight = 0;

        const item = weekData[0]
        const date = getMondayOfISOWeek(item.year, item.week)
        const dateString = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

        return (
          <Box key={index} display="flex" flexDirection="column-reverse" alignItems="center" ml={1} mr={1} style={{height: 250}}>
            {Object.entries(projectGroups).map(([projectId, projectData]) => {
              const total_thread_count = sumBy(projectData, 'thread_count');
              stackedHeight += total_thread_count;
              const normalizedHeight = (total_thread_count / maxStackedHeight) * 250; // 250 is the container height
              return (
                <Tooltip title={`${projectData[0]?.project_name} : ${projectData[0]?.thread_count}`} key={projectId}>
                  <Box
                    sx={{
                      backgroundColor: StringUtils.stringToColor(projectId),
                      width: "24px",
                      height: `${normalizedHeight}px`,
                      minHeight: '4px'
                    }}
                  ></Box>
                </Tooltip>
              );
            })}
            <Typography variant="body2" sx={{ marginTop: "8px" }}>
              {stackedHeight}
            </Typography>
            <Typography variant="body2" sx={{ textAlign: "center", width: "100px" }}>
              {dateString}
            </Typography>
          </Box>
        );
      })}
    </Box>
  )
};

export default ActivityDashboard;
