import React from "react";
import {Box, Button, Card, CardContent, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"
import useUserContext from "../contexts/useUserContext";
import UserMessage from "./reusable/message/UserMessage";
import AIMessage from "./reusable/message/AIMessage";

const NoProjectsBanner = ({ onClick }: { onClick: () => void }) => {
  const { user } = useUserContext()

  const headline = `Hey there, ${user?.first_name}!`
  const body = `It looks like you don't have any projects yet. Let's create one to get started.`

  const userMessage = "What can I do on this platform?"
  const assistantResponse = "Knory is a tool to create customizable AI Agents enriched with your organizations knowledge and practices. You can create projects, upload documents, and invite team members to chat with a customizable AI assistant."

  return (
    <Card sx={{background: `linear-gradient(135deg, rgba(91, 228, 255, 0.2), rgba(0, 111, 255, 0.2)) rgb(255, 255, 255)`, color: "black"}}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid sm={12} md={6}>
            <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "start"}}>
              <div style={{ flexGrow: 1 }}>
                <Typography variant={"h4"} gutterBottom>{headline}</Typography>
                <Typography>{body}</Typography>
              </div>
              <Button
                variant={"contained"}
                sx={{ flexShrink: 0, mt: 4 }}
                onClick={onClick}
              >Create my first project</Button>
            </div>
          </Grid>
          <Grid sm={12} md={6}>
            <Card sx={{ backgroundColor: "background.default"}}>
              <CardContent>
                <UserMessage user={user!} message={userMessage} />
                <AIMessage name={"Knory Assistant"} message={assistantResponse} disableCopy />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default NoProjectsBanner
