import React from "react";
import {Typography, useTheme} from "@mui/material";


const Logo = () => {
  const theme = useTheme()

  return (
    <Typography variant={"h5"} sx={{ color: theme.palette.surface.contrastText }}>
      Ask{" "}
      <span style={{
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      }}>
            Knory
          </span>
    </Typography>
  )
}

export default Logo
