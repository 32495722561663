import React from "react";
import {Box, Button, Card, CardContent, Container, Grid, Divider, Stack, Typography} from "@mui/material";
import {useAnimationProps} from "../components/ProjectImage";
import Logo from "../components/Logo";


const LoginPage = () => {
  return (
    <Box sx={{
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...(useAnimationProps())
    }}>
      <Container maxWidth="lg" sx={{ color: 'white', textAlign: 'center', alignItems: "center", justifyContent: "center" }}>
        {/* Hero Section */}
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" gutterBottom sx={{ color: 'white' }}>
            {/* Ask <span style={{ fontSize: "50px", fontStyle: 'italic', textDecoration: 'underline' }}>Knory</span> */}
            Ask <span style={{ fontSize: "50px", fontStyle: 'italic' }}>Knory</span>
          </Typography>
          <Typography variant="h5" sx={{ color: 'white', mb: 2 }}>
            Enterprise-level AI for School Districts
          </Typography>
        </Box>

        <Box sx={{ my: 4, display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Card sx={{ width: 400 }}>
            <CardContent>
              <Stack spacing={2}>
                <div>
                  {/* <Logo /> */}
                  <Typography variant={"body1"} color={"text.secondary"}>
                    Create customized AI Agents to help educators answer questions, draft content, and save time.
                  </Typography>
                </div>
                <Divider />
                <Button
                  variant={"contained"}
                  sx={{ background: "#4285F4" }}  // Google blue
                  startIcon={
                    <img
                      src={"/static/images/googleLogo.svg"}
                      alt={"Google logo"}
                      style={{ height: "18px", width: "18px" }}
                    />
                  }
                  href="/auth/google/login"
                >Login with Google</Button>
              </Stack>
            </CardContent>
          </Card>
        </Box>

        {/* Features Section */}
        <Grid container spacing={4} sx={{ my: 4 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" sx={{ color: 'white' }}>Secure & Compliant</Typography>
            <Typography sx={{ color: 'white' }}>
              Fully compliant with FERPA and COPPA, ensuring data security and privacy.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" sx={{ color: 'white' }}>Built for School Districts</Typography>
            <Typography sx={{ color: 'white' }}>
              Customizable, purpose-built platform to harness the power of generative AI.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" sx={{ color: 'white' }}>Human Centered Approach</Typography>
            <Typography sx={{ color: 'white' }}>
              Tools designed for collaboration with a "human-in-the-loop" approach to AI.
            </Typography>
          </Grid>
        </Grid>

        {/* Call to Action */}
        <Box sx={{ textAlign: 'center', my: 4 }}>
          <Typography variant="body2" sx={{ color: 'white', fontStyle: 'italic' }}>
            "It's like an Enterprise version of ChatGPT built just for schools"
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default LoginPage
