import Search from "./Search";

export enum UserType {
  user = "user",
  system = "system",
  collaborator = "collaborator",
}

export default interface ConversationThreadMessage {
  _id: string
  user_id: string,
  project_id: string,
  thread_id: string,
  feedback: number,
  body: string,
  user_type: UserType,
  system_response: Search,
  created_at: string,
  user: {
    _id: string,
    first_name: string,
    last_name: string,
    email: string,
    picture: string,
  },
}
