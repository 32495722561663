import {Divider, Stack, Switch, type SwitchProps, Typography} from "@mui/material";


interface LabeledSwitchProps extends SwitchProps {
  primary: string
  secondary?: string
}

const LabeledSwitch = (props: LabeledSwitchProps) => {

  const { primary, secondary, edge, ...rest } = props

  return (
    <div style={{ display: "flex" }}>
      <Stack sx={{ flexGrow: 1 }}>
        <Typography>{props.primary}</Typography>
        {props.secondary && (
          <Typography color={"text.secondary"}>{props.secondary}</Typography>
        )}
      </Stack>
      <Divider orientation={"vertical"} flexItem />
      <Switch edge={"end"} {...rest} />
    </div>
  )
}

export default LabeledSwitch
