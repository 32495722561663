import {Box, Button, Card, CardContent, Divider, Stack, Typography} from "@mui/material";
import {useAnimationProps} from "../components/ProjectImage";
import Logo from "../components/Logo";
import React from "react";

const NoAccessPage = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...(useAnimationProps())
      }}
    >
      <Card sx={{ width: 690 }}>
        <CardContent>
          <Stack spacing={2}>
            {/*<div>*/}
            {/*  <Logo />*/}
            {/*  <Typography variant={"body1"} color={"text.secondary"}>Your knowledge repository.</Typography>*/}
            {/*</div>*/}
            {/*<Divider />*/}
            <Typography variant={"h6"}>Access restricted to school accounts</Typography>
            <Typography variant={"body1"}>
              Sorry, access is limited to school accounts. Please use your school account to log in, or contact your school's IT department for assistance.
            </Typography>
            <Divider />
            <Button
              variant={"contained"}
              sx={{ background: "#4285F4", maxWidth: "313px" }}  // Google blue
              startIcon={
                <img
                  src={"/static/images/googleLogo.svg"}
                  alt={"Google logo"}
                  style={{ height: "18px", width: "18px" }}
                />
              }
              href="/auth/google/login"
            >Login with Google</Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  )
}

export default NoAccessPage
