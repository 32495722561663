import React, {useEffect, useState} from "react";

import {useParams} from 'react-router-dom'
import { format, isToday, isWithinInterval, startOfDay, endOfDay, subDays } from 'date-fns';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import ConversationThread from "../../types/ConversationThread";
import ConversationThreadListItem from "./ConversationThreadListItem";
import useConversation from "../../api/useConversationThread";

interface ConversationThreadListProps {
  handleClickThread:(thread:ConversationThread) => void
  projectId?:string
  activeThreadId?:string
}


const ConversationThreadList = ({activeThreadId,projectId,handleClickThread}:ConversationThreadListProps) => {
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState<ConversationThread[]>();
  const api = useConversation()
  const params = useParams<{threadId: string}>()

  const groupedConversations: { [key: string]: ConversationThread[] } = (conversations || [])?.reduce((acc:any, c) => {
    const date = new Date(`${c.updated_at}Z`);
    let label: string;
    if (isToday(date)) {
      label = 'Today';
    } else if (isWithinInterval(date, { start: startOfDay(subDays(new Date(), 7)), end: endOfDay(new Date()) })) {
      label = 'Previous 7 Days';
    } else {
      label = format(date, 'MMMM yyyy');
    }

    if (!acc[label]) {
      acc[label] = [];
    }

    acc[label].push(c);
    return acc;
  }, {});

  useEffect(() => {
    let existingThread = conversations?.find(t => t._id === params.threadId)
    if (!conversations || !existingThread) {
      if (!conversations || conversations.length === 0) setLoading(true)
      api.listThreads(projectId)
        .then((response) => setConversations(response.data))
        .catch((error) => console.log("ChatMessageResultView.useEffect.listThreads.catch", error))
        .finally(() => setLoading(false))
    }
  }, [params.threadId]);

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {loading ? (
        [...Array(10)].map((_, i) => (
          <ListItem alignItems={"flex-start"} key={i}>
            <Skeleton height={50} width={"100%"}/>
          </ListItem>
        ))
      ) : (
        Object.keys(groupedConversations).map((label:string, i:number) => (
          <div key={i}>
            <ListSubheader sx={{zIndex: 100}}>{label}</ListSubheader>
            {groupedConversations[label].map((c,i) => (
              <ConversationThreadListItem key={i} thread={c} activeThreadId={activeThreadId} handleClick={handleClickThread}/>
            ))}
          </div>
        ))
      )}
    </List>
  );
}

export default ConversationThreadList
