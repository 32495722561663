import {Dialog, DialogContent, DialogContentText, type DialogProps, DialogTitle, DialogActions, Button} from "@mui/material";
import useProjects from "../api/useProjects";
import {useEffect, useState} from "react";
import Project from "../types/Project";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";

interface DeleteProjectDialogProps extends DialogProps{
  projectId: string
}

const DeleteProjectDialog = (props: DeleteProjectDialogProps) => {
  const navigate = useNavigate()

  const { projectId, children, ...rest } = props
  const { getProject, deleteProject } = useProjects()

  const [project, setProject] = useState<Project>()
  const [deleting, setDeleting] = useState(false)

  const handleClose = () => {
    if (rest.onClose) {
      // @ts-ignore
      rest.onClose()
    }
  }

  const handleDelete = async () => {
    setDeleting(true)
    deleteProject(projectId)
      .then(() => navigate("/projects"))
      .catch((err) => console.log(err))
      .finally(() => setDeleting(false))
  }

  const dialogContents = () => (
    <>
      <DialogTitle>{`Delete ${project?.name || "this project"}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`${project?.name || "this project"} will be permanently removed from your organization.`}
          <br />
          <b>This cannot be undone.</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={deleting} variant={"text"} color={"error"} onClick={handleDelete}>Delete</LoadingButton>
      </DialogActions>
    </>
  )

  useEffect(() => {
    if (rest.open) {
      getProject(projectId)
        .then((response) => {
          setProject(response.data)
        })
        .catch((err) => console.log(err))
    }
  }, [props.open])

  return (
    <Dialog {...rest}>
      {children || dialogContents()}
    </Dialog>
  )
}

export default DeleteProjectDialog
